import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { PrimaryColor, SecondaryColor } from "../../app/AppThemeProvider";

const firebaseConfig = {
  apiKey: "AIzaSyDjtsmpZ0nPEDTOJaoxl0o71fFDWtwRQTA",
  authDomain: "adviceu-d5b58.firebaseapp.com",
  projectId: "adviceu-d5b58",
  storageBucket: "adviceu-d5b58.appspot.com",
  messagingSenderId: "495483461563",
  appId: "1:495483461563:web:5834022e462eea732a2e9e",
  measurementId: "G-NVB9ZM6L53",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const ForgotPassword: React.FC = () => {
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleClose = () => {
    setForgotPasswordOpen(false);
    setError("");
    setForgotPasswordEmail("");
    setForgotPasswordSuccess(false);
  };

  const handleForgotPassword = () => {
    setError("");
    if (!forgotPasswordEmail) {
      setError("Please enter your registered email address.");
      return;
    }

    firebase
      .auth()
      .sendPasswordResetEmail(forgotPasswordEmail)
      .then(() => {
        setForgotPasswordSuccess(true);
      })
      .catch((error: any) => {
        setError(error.message);
      });
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleForgotPasswordOpen}
        sx={{
          textAlign: "right",
          fontFamily: "productRegular",
          color: "grey",
          textTransform: "none",
        }}
      >
        Forgot password?
      </Button>
      <Dialog open={isForgotPasswordOpen} onClose={handleClose}>
        <Box sx={{ padding: "20px" }}>
          <DialogTitle style={{ textAlign: "center" }}>
            <Typography variant="h4" sx={{ fontFamily: "productBold" }}>
              Forgot Password?
            </Typography>
          </DialogTitle>
          <DialogContent>
            {forgotPasswordSuccess ? (
              <Typography
                variant="body2"
                color="success"
                sx={{ marginBottom: "20px", fontFamily: "productRegular" }}
              >
                Password reset email sent successfully. Please check your email.
              </Typography>
            ) : (
              <>
                <Typography
                  variant="body2"
                  sx={{ marginBottom: "10px", fontFamily: "productRegular" }}
                >
                  Please enter your registered email address to receive a
                  password reset email.
                </Typography>
                <TextField
                  label=""
                  fullWidth
                  required
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  variant="standard"
                  sx={{
                    fontFamily: "productBold",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    },
                  }}
                />
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "10px" }}
                  >
                    {error}
                  </Typography>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            {forgotPasswordSuccess ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleClose}
                sx={{
                  borderRadius: "20px",
                  width: "300px",
                  fontFamily: "productRegular",
                  textTransform: "none",
                  background: SecondaryColor,
                  "&:hover": {
                    background: "orange",
                  },
                  marginBottom: "20px",
                }}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleForgotPassword}
                sx={{
                  borderRadius: "20px",
                  width: "300px",
                  fontFamily: "productRegular",
                  textTransform: "none",
                  background: PrimaryColor,
                  "&:hover": {
                    background: "orange",
                  },
                  marginBottom: "20px",
                }}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
