import { BackgroundColor } from "../AppThemeProvider";
import { Grid } from "@mui/material";
import HeaderMentor from "../../components/HeaderMentor";
import Footer from "../../components/Footer";
import MBanner from "./MBanner";
import FAQ from "../faq/FAQ";
import ScrollingHeader from "../../components/scrolling-header-mentors/ScrollingHeader";
import Payments from "./Payments";
import Timeline from "./Timeline";
import GetStarted from "./GetStarted";

export default function MLandingPage() {
  return (
    <div>
      <Grid sx={{ bgcolor: BackgroundColor }}>
        <HeaderMentor />
      </Grid>
      <MBanner />
      <ScrollingHeader />
      <Payments />
      <Timeline />
      <GetStarted />
      <FAQ data={"Mentor"} />
      <Footer />
    </div>
  );
}
