import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDjtsmpZ0nPEDTOJaoxl0o71fFDWtwRQTA",
  authDomain: "adviceu-d5b58.firebaseapp.com",
  projectId: "adviceu-d5b58",
  storageBucket: "adviceu-d5b58.appspot.com",
  messagingSenderId: "495483461563",
  appId: "1:495483461563:web:5834022e462eea732a2e9e",
  measurementId: "G-NVB9ZM6L53",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
