import React from "react";
import { BackgroundColorLight, SecondaryColor } from "../AppThemeProvider";
import { Box, Button, Grid, Typography } from "@mui/material";
import "../../fonts/fonts.css";

export default function GetStarted() {
  return (
    <Grid container sx={containerStyle}>
      <Grid container spacing={4} sx={gridStyle}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              borderRadius: "20px",
              background: SecondaryColor,
              height: { xs: "380px", md: "450px", lg: "380px" },
              padding: "20px",
            }}
          >
            <Grid container>
              <Grid item xs={12} sx={{ marginLeft: "5%", paddingTop: "20px" }}>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "productBold", color: "#FFFFFF" }}
                >
                  We're empowering higher ed students to share their authentic
                  voice and propel high school students forward in their
                  journey.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              borderRadius: "20px",
              background: "#000000",
              height: { xs: "380px", md: "450px", lg: "380px" },
              padding: "20px",
            }}
          >
            <Grid container>
              <Grid item xs={12} sx={{ marginLeft: "5%", marginTop: "40px" }}>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "productRegular", color: "grey" }}
                >
                  For Mentors
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: "5%", marginTop: "10px" }}>
                <Button variant="contained" sx={buttonStyle} href="/apply">
                  Join the team
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: "5%", marginTop: "30px" }}>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "productBold", color: "#FFFFFF" }}
                >
                  Your Future Starts Here
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "productRegular",
                    color: "#FFFFFF",
                    marginTop: "10px",
                  }}
                >
                  Don't worry, we're here to help you cut through the noise.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "0px 166px 30px" },
  direction: "column",
  bgcolor: BackgroundColorLight,
  justifyContent: "center",
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "1500px",
  alignContent: "space-between",
  paddingBottom: "20px",
};

const buttonStyle = {
  borderRadius: "10px",
  fontFamily: "productBold",
  background: "grey",
  marginTop: "10px",
};
