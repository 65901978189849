import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { SecondaryColor } from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";

export default function Steps() {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        zIndex: 2,
        marginTop: useIsDesktop() ? "30px" : "0px",
        marginBottom: "30px",
      }}
    >
      <Grid item xs={4}>
        <Grid
          container
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <Typography variant="h5" sx={numberStyle}>
            01
          </Typography>
          <Tooltip
            title={
              useIsDesktop() ? (
                <h1 style={{ fontFamily: "productBold", textAlign: "center" }}>
                  Find Your Perfect Mentor Match
                </h1>
              ) : (
                <h3 style={{ fontFamily: "productBold", textAlign: "center" }}>
                  Find Your Perfect Mentor Match
                </h3>
              )
            }
            placement="top"
          >
            <Box sx={boxStyle}>
              <JoinLeftIcon sx={imgStyle} />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <Typography variant="h5" sx={numberStyle}>
            02
          </Typography>
          <Tooltip
            title={
              useIsDesktop() ? (
                <h1 style={{ fontFamily: "productBold", textAlign: "center" }}>
                  Have Them View And Improve Your University Application
                </h1>
              ) : (
                <h3 style={{ fontFamily: "productBold", textAlign: "center" }}>
                  Have Them View And Improve Your University Application
                </h3>
              )
            }
            placement="top"
          >
            <Box sx={boxStyle}>
              <GroupsIcon sx={imgStyle} />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <Typography variant="h5" sx={numberStyle}>
            03
          </Typography>
          <Tooltip
            title={
              useIsDesktop() ? (
                <h1 style={{ fontFamily: "productBold", textAlign: "center" }}>
                  Get Into Your Dream School
                </h1>
              ) : (
                <h3 style={{ fontFamily: "productBold", textAlign: "center" }}>
                  Get Into Your Dream School
                </h3>
              )
            }
            placement="top"
            sx={{ color: "red" }}
          >
            <Box sx={boxStyle}>
              <SchoolIcon sx={imgStyle} />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}

const numberStyle = {
  fontWeight: "bold",
  marginRight: "20px",
};

const boxStyle = {
  borderRadius: "360px",
  background: SecondaryColor,
  width: { xs: "50px", sm: "100px" },
  height: { xs: "50px", sm: "100px" },
  textAlign: "center",
  padding: "20px",
};

const imgStyle = {
  width: { xs: "50px", sm: "100px" },
  height: { xs: "50px", sm: "100px" },
};
