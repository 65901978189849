import React, { useState } from "react";
import { BackgroundColorLight } from "../AppThemeProvider";
import { Box, Grid, Link, TextField, Typography } from "@mui/material";
import { useIsDesktop } from "../../utils/useIsDesktop";
import { university } from "../../data/Universities/Universities";
import { InputAdornment, IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import "../../fonts/fonts.css";

export default function GetStarted() {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredUniversities = university.filter((uni) => {
    const searchWords = searchQuery.toLowerCase().trim().split(/\s+/);
    return searchWords.some((word) => uni.name.toLowerCase().includes(word));
  });

  const handleButtonClick = (university: any) => {
    setSearchQuery(university.name);
  };

  return (
    <Grid container sx={containerStyle}>
      <Grid container spacing={2} sx={gridStyle}>
        <Grid item xs={12}>
          <Typography
            variant={useIsDesktop() ? "h3" : "h4"}
            sx={{
              fontFamily: "productBold",
              lineHeight: "1.6",
            }}
          >
            Time to take action. Let's chat!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={useIsDesktop() ? "h6" : "body1"}
            sx={{
              fontFamily: "productRegular",
              lineHeight: "2",
              paddingLeft: "15vw",
              paddingRight: "15vw",
            }}
          >
            Not sure where to start? Don't worry, we've got you covered! Find an
            AdviceU mentor at your future school to get started.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Search Universities"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              width: "100%",
              maxWidth: "1150px",
              fontFamily: "productRegular",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton disabled>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container sx={{ marginTop: "20px", marginBottom: "20px" }}>
          {searchQuery !== "" &&
            filteredUniversities.map((university, index) => (
              <Grid item xs={12} md={6} lg={4}>
                <Link key={index} href={`/mentors/${university.name}`}>
                  <button
                    style={buttonStyle}
                    onClick={() => handleButtonClick(university)}
                  >
                    <Box sx={boxStyle}>
                      <img src={university.image} alt="" style={imgStyle} />
                    </Box>
                  </button>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 166px 30px" },
  direction: "column",
  bgcolor: BackgroundColorLight,
  justifyContent: "center",
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "1500px",
  textAlign: "center",
  paddingBottom: "20px",
};

const boxStyle = {
  bgcolor: "white",
  borderRadius: "20px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  width: "300px",
  height: "150px",
};

const imgStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
};

const buttonStyle = {
  padding: 0,
  border: "none",
  borderRadius: "20px",
  background: "transparent",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "none",
  },
};
