import React from "react";
import { BackgroundColor, TertiaryColor } from "../app/AppThemeProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Logo from "../assets/logo_white.svg";
import Typography from "@mui/material/Typography";

export default function Footer() {
  return (
    <Box
      sx={{ padding: { xs: "26px 30px", md: "65px 96px 35px" } }}
      bgcolor={TertiaryColor}
    >
      <Grid container>
        <Grid
          item
          container
          md={4}
          sx={{
            order: { md: "0" },
            flexDirection: "row",
            justifyContent: { xs: "start" },
            alignItems: { xs: "center", md: "start" },
            alignContent: { md: "start" },
            marginBottom: { xs: "36px" },
          }}
        >
          <Grid item xs={12} md={12} sx={{ marginBottom: { md: "20px" } }}>
            <Box sx={{ width: { xs: "96px", md: "144px" } }}>
              <img src={Logo} style={{ width: "100%" }} alt={""} />
            </Box>
            <Box marginTop="10px" color={BackgroundColor}>
              © 2023 AdviceU Inc. All rights reserved.
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          sx={{ color: BackgroundColor }}
          marginBottom="24px"
        >
          <Box sx={footerColumnStyle}>
            <Typography fontSize="18px" fontWeight="bold" marginBottom="10px">
              Policies
            </Typography>

            <Box marginBottom="5px">
              <Link
                href="/cookies"
                target="_blank"
                style={linkStyle}
                underline="hover"
              >
                Cookie Policy
              </Link>
            </Box>
            <Box marginBottom="5px">
              <Link
                href="/acceptable_use"
                target="_blank"
                style={linkStyle}
                underline="hover"
              >
                Acceptable Use Policy
              </Link>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          sx={{ color: BackgroundColor }}
          marginBottom="24px"
        >
          <Box sx={footerColumnStyle}>
            <Typography fontSize="18px" fontWeight="bold" marginBottom="10px">
              Notices
            </Typography>

            <Box marginBottom="5px">
              <Link
                href="/privacy_notice"
                target="_blank"
                style={linkStyle}
                underline="hover"
              >
                Privacy Notice
              </Link>
            </Box>
            <Box marginBottom="5px">
              <Link
                href="/member_portal_terms_of_service"
                target="_blank"
                style={linkStyle}
                underline="hover"
              >
                Terms of Use
              </Link>
            </Box>
            <Box marginBottom="5px">
              <Link
                href="/disclaimer"
                target="_blank"
                style={linkStyle}
                underline="hover"
              >
                Disclaimer
              </Link>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} md={2} sx={{ color: BackgroundColor }}>
          <Box sx={footerColumnStyle}>
            <Typography fontSize="18px" fontWeight="bold" marginBottom="10px">
              About
            </Typography>

            <Box marginBottom="5px">
              <Link
                href="/about"
                target="_blank"
                style={linkStyle}
                underline="hover"
              >
                About Us
              </Link>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} md={2} sx={{ color: BackgroundColor }}>
          <Box sx={footerColumnStyle}>
            <Typography fontSize="18px" fontWeight="bold" marginBottom="10px">
              Contact
            </Typography>

            <Box marginBottom="20px">
              <span style={linkStyle}>
                330 Phillip Street N-1204, Waterloo, Ontario, N2L 3W9
              </span>
            </Box>
            <Box marginBottom="5px">
              <span style={linkStyle}>+ 1 (416) 918-9713</span>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const linkStyle = {
  color: "inherit",
};

const footerColumnStyle = {
  flexDirection: "column",
  padding: {
    md: "0px 10px 0px 0px",
    xs: "0px 20px 0px 0px",
  },
};
