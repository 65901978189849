import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Calendar from "./Calendar";
import Header from "../../components/Header";
import { Grid, Typography } from "@mui/material";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import Footer from "../../components/Footer";
import { useIsDesktop } from "../../utils/useIsDesktop";
import useFetchEmail from "../../utils/useFetchEmail";

export default function BookingPage() {
  const user_email = useFetchEmail();
  const [email, setEmail] = useState(user_email);
  const [mentorFullName, setMentorFullName] = useState("");
  const [mentorUniversity, setMentorUniversity] = useState("");
  const [mentorProgram, setMentorProgram] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>("");
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    setEmail(user_email);
  }, [user_email]);

  useEffect(() => {
    const pathParts = window.location.pathname.split("/").filter(Boolean);
    if (pathParts.length === 4 && pathParts[0] === "booking") {
      const mentorFullName = decodeURIComponent(pathParts[1]);
      const mentorUniversity = decodeURIComponent(pathParts[2]);
      const mentorProgram = decodeURIComponent(pathParts[3]);

      setMentorFullName(mentorFullName);
      setMentorUniversity(mentorUniversity);
      setMentorProgram(mentorProgram);
    }
  }, []);

  const handleButtonClick = (text: string) => {
    setSelectedButton(text);
  };

  const handleTimeSlotSelection = (timeSlot: string) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleDateSelection = (month: string, day: string) => {
    setSelectedDate(month + " " + day);
  };

  const handleBooking = () => {
    if (selectedButton === "Application Edits") {
      window.open("https://buy.stripe.com/4gw00p8yP6VedUI5kk", "_blank");
    } else if (selectedButton === "60 Min Meeting") {
      window.open("https://buy.stripe.com/14kdRf2ar7Zi6sg3cd", "_blank");
    } else if (selectedButton === "Personal Mentor") {
      window.open("https://buy.stripe.com/9AQdRf7uLcfy8Ao4gi", "_blank");
    } else {
      console.log(selectedTimeSlot);
    }
  };

  const isFormValid = () => {
    return (
      email !== "" &&
      mentorFullName !== "" &&
      mentorUniversity !== "" &&
      mentorProgram !== "" &&
      selectedButton !== "" &&
      selectedDate !== "" &&
      selectedTimeSlot !== "" &&
      selectedTimeSlot
    );
  };

  return (
    <div style={{ background: BackgroundColor }}>
      <Grid sx={{ bgcolor: BackgroundColor }}>
        <Header />
      </Grid>
      <Grid
        container
        spacing={4}
        sx={{
          padding: {
            xs: "0px 20px 10px",
            md: "20px 80px 30px",
          },
          textAlign: "center",
          marginBottom: "40px",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography
            variant={useIsDesktop() ? "h2" : "h3"}
            sx={{ fontFamily: "productBold" }}
          >
            Let's get started.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "-30px" }}>
          <Typography
            variant={useIsDesktop() ? "h2" : "h4"}
            sx={{ fontFamily: "productBold" }}
          >
            Book your gateway today.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "productRegular", marginTop: "20px" }}
          >
            Your Email*
          </Typography>
          <TextField
            label=""
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            sx={{
              fontFamily: "productBold",
              background: "#FFFFFF",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontFamily: "productRegular" }}>
            Mentor's Full Name*
          </Typography>
          <TextField
            label=""
            fullWidth
            required
            value={mentorFullName}
            onChange={(e) => setMentorFullName(e.target.value)}
            variant="outlined"
            sx={{
              fontFamily: "productBold",
              background: "#FFFFFF",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontFamily: "productRegular" }}>
            Mentor's University*
          </Typography>
          <TextField
            label=""
            fullWidth
            required
            value={mentorUniversity}
            onChange={(e) => setMentorUniversity(e.target.value)}
            variant="outlined"
            sx={{
              fontFamily: "productBold",
              background: "#FFFFFF",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontFamily: "productRegular" }}>
            Mentor's Program*
          </Typography>
          <TextField
            label=""
            fullWidth
            required
            value={mentorProgram}
            onChange={(e) => setMentorProgram(e.target.value)}
            variant="outlined"
            sx={{
              fontFamily: "productBold",
              background: "#FFFFFF",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontFamily: "productRegular" }}>
            Your Purchase Package*
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: "productRegular" }}>
            Not sure which one?
            <Button
              href="/pricing"
              variant="text"
              target="_blank"
              sx={{
                fontFamily: "productRegular",
                color: PrimaryColor,
                textTransform: "none",
              }}
            >
              Click here.
            </Button>
          </Typography>
          <Grid container spacing={2} sx={{ mt: "5px" }}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "productBold",
                  background:
                    selectedButton === "Application Edits"
                      ? PrimaryColor
                      : "#FFFFFF",
                  color:
                    selectedButton === "Application Edits"
                      ? "#FFFFFF"
                      : "#000000",
                  width: useIsDesktop() ? "250px" : "300px",
                }}
                onClick={() => handleButtonClick("Application Edits")}
              >
                Application Edits
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "productBold",
                  background:
                    selectedButton === "60 Min Meeting"
                      ? PrimaryColor
                      : "#FFFFFF",
                  color:
                    selectedButton === "60 Min Meeting" ? "#FFFFFF" : "#000000",
                  width: useIsDesktop() ? "250px" : "300px",
                }}
                onClick={() => handleButtonClick("60 Min Meeting")}
              >
                60 Min Meeting
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "productBold",
                  background:
                    selectedButton === "Personal Mentor"
                      ? PrimaryColor
                      : "#FFFFFF",
                  color:
                    selectedButton === "Personal Mentor"
                      ? "#FFFFFF"
                      : "#000000",
                  width: useIsDesktop() ? "250px" : "300px",
                }}
                onClick={() => handleButtonClick("Personal Mentor")}
              >
                Ultimate
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Calendar
            name={mentorFullName + " " + mentorUniversity + " " + mentorProgram}
            selectedTimeSlot={selectedTimeSlot}
            onTimeSlotSelection={handleTimeSlotSelection}
            selectedDate={selectedDate}
            onDateSelection={handleDateSelection}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBooking}
            disabled={!isFormValid()}
            sx={{ fontFamily: "productBold", background: PrimaryColor }}
          >
            Book Now
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
