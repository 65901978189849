import React from "react";
import "./styles.css";
import { Box, Typography } from "@mui/material";
import { BackgroundColor } from "../../app/AppThemeProvider";

import ShivamSuri from "../../assets/mentorImages/ShivamSuri.JPG";
import JodhvirBassi from "../../assets/mentorImages/JodhvirBassi.jpg";

const items = [
  //{
  //name: "Vivek Upadhyay",
  //profession: "Cyber Security Expert",
  //img: ShivamSuri,
  //},
  {
    name: "Shivam Suri",
    profession: "Software Engineer",
    img: ShivamSuri,
  },
  {
    name: "Jodhvir Bassi",
    profession: "Software Engineer",
    img: JodhvirBassi,
  },
];

const ScrollingHeader = () => {
  return (
    <Box
      sx={{
        background: BackgroundColor,
        marginTop: "-10px",
        paddingBottom: "30px",
      }}
    >
      <div className="marquee-container">
        <div className="marquee" style={{ padding: "20px" }}>
          <div
            className="marquee-content"
            style={{
              display: "flex",
              width: `${items.length * 300}px`,
            }}
          >
            {items.map((item) => (
              <Box
                key={item.name}
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "20px",
                  width: "270px",
                  height: "360px",
                  margin: "0 30px",
                  padding: "20px",
                }}
              >
                <img
                  src={item.img}
                  alt=""
                  style={{
                    borderRadius: "20px",
                    width: "270px",
                    height: "270px",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "productBold",
                    textAlign: "center",
                    mt: "10px",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "productRegular",
                    textAlign: "center",
                    color: "grey",
                  }}
                >
                  Future {item.profession}
                </Typography>
              </Box>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ScrollingHeader;
