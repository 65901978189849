import { Box, Button, Grid, Typography } from "@mui/material";

import { TrendingUp } from "@mui/icons-material";
import React, { useState } from "react";
import { useIsDesktop } from "../../utils/useIsDesktop";
import {
  BackgroundColor,
  BackgroundColorLight,
  PrimaryColor,
  SecondaryColor,
} from "../AppThemeProvider";

const bookingData = [
  { package: "Application Edits" },
  { package: "60 Minute Meeting" },
  { package: "Personal Mentor" },
];

export default function StudentCard() {
  const [isAEPressed, setIsAEPressed] = useState(false);
  const [isMMPressed, setIsMMPressed] = useState(false);
  const [isPMPressed, setIsPMPressed] = useState(false);

  function pressAE() {
    setIsAEPressed(true);
    setIsMMPressed(false);
    setIsPMPressed(false);
  }

  function pressMM() {
    setIsAEPressed(false);
    setIsMMPressed(true);
    setIsPMPressed(false);
  }

  function pressPM() {
    setIsAEPressed(false);
    setIsMMPressed(false);
    setIsPMPressed(true);
  }

  return (
    <Box sx={boxStyle}>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", fontFamily: "productRegular" }}
      >
        Select from our diverse packages
      </Typography>
      <Grid container spacing={1} sx={{ mt: "10px" }}>
        {isAEPressed || isMMPressed || isPMPressed ? (
          <>
            <Grid item>
              <TrendingUp sx={{ color: PrimaryColor }} />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic", fontFamily: "productRegular" }}
              >
                {isAEPressed ? 7 : null} {isMMPressed ? 12 : null}
                {isPMPressed ? 3 : null} others are interested in this package
                right now!
              </Typography>
            </Grid>
          </>
        ) : null}
        <Grid
          container
          spacing={1}
          sx={{ width: useIsDesktop() ? "90%" : "100%", mt: "3px" }}
        >
          <Grid item xs={6} md={4}>
            <Button
              onClick={pressAE}
              variant="contained"
              sx={{
                borderRadius: "20px",
                color: "#000000",
                bgcolor: BackgroundColorLight,
                width: "100%",
                fontFamily: "productRegular",
                boxShadow: isAEPressed
                  ? `0px 0px 0px 4px ${SecondaryColor}`
                  : null,
                "&:hover": {
                  bgcolor: BackgroundColorLight,
                  boxShadow: `0px 0px 0px 4px ${SecondaryColor}`,
                },
              }}
            >
              {bookingData[0].package}
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button
              onClick={pressMM}
              variant="contained"
              sx={{
                borderRadius: "20px",
                color: "#000000",
                bgcolor: BackgroundColorLight,
                width: "100%",
                fontFamily: "productRegular",
                boxShadow: isMMPressed
                  ? `0px 0px 0px 4px ${SecondaryColor}`
                  : null,
                "&:hover": {
                  bgcolor: BackgroundColorLight,
                  boxShadow: `0px 0px 0px 4px ${SecondaryColor}`,
                },
              }}
            >
              {bookingData[1].package}
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button
              onClick={pressPM}
              variant="contained"
              sx={{
                borderRadius: "20px",
                color: "#000000",
                bgcolor: BackgroundColorLight,
                width: "100%",
                fontFamily: "productRegular",
                boxShadow: isPMPressed
                  ? `0px 0px 0px 4px ${SecondaryColor}`
                  : null,
                "&:hover": {
                  bgcolor: BackgroundColorLight,
                  boxShadow: `0px 0px 0px 4px ${SecondaryColor}`,
                },
              }}
            >
              {bookingData[2].package}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "10px" }}>
          <Grid item xs={6}>
            <Button variant="text" sx={showMoreButtonStyle} href="/pricing">
              See what's included
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "right" }}
            >
              {isAEPressed ? "$29.99" : null}
              {isMMPressed ? "$49.99" : null}
              {isPMPressed ? "$99.99" : null}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "10px" }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={signUpButtonStyle}
              href="/for_students"
            >
              Learn More
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const boxStyle = {
  bgcolor: BackgroundColor,
  borderRadius: "20px",
  padding: "25px",
};

const showMoreButtonStyle = {
  marginTop: "10px",
  textTransform: "none",
  textDecoration: "underline",
  color: SecondaryColor,
  mt: "1px",
  fontFamily: "productRegular",
};

const signUpButtonStyle = {
  borderRadius: "20px",
  color: BackgroundColor,
  bgcolor: PrimaryColor,
  textAlign: "center",
  fontWeight: "bold",
  width: "100%",
  textTransform: "none",
  fontFamily: "productBold",
};
