import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { UserCredential } from "@firebase/auth-types";
import { PrimaryColor, SecondaryColor } from "../../app/AppThemeProvider";
import ForgotPassword from "./ForgotPassword";
import { Google } from "@mui/icons-material";

const firebaseConfig = {
  apiKey: "AIzaSyDjtsmpZ0nPEDTOJaoxl0o71fFDWtwRQTA",
  authDomain: "adviceu-d5b58.firebaseapp.com",
  projectId: "adviceu-d5b58",
  storageBucket: "adviceu-d5b58.appspot.com",
  messagingSenderId: "495483461563",
  appId: "1:495483461563:web:5834022e462eea732a2e9e",
  measurementId: "G-NVB9ZM6L53",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export interface LoginProps {
  onLoginStatusChange: (status: boolean) => void;
}

const checkAuthStatus = () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(!!user);
    });
  });
};

const Login: React.FC<LoginProps> = ({ onLoginStatusChange }) => {
  const [isLoginFormOpen, setLoginFormOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    checkAuthStatus().then((isLoggedIn) => {
      if (isLoggedIn) {
        setIsLoggedIn(true);
        onLoginStatusChange(true);
        const user = firebase.auth().currentUser;
        if (user) {
          setUsername(user.displayName || user.email || "");
        }
      }
    });
  }, []);

  const handleLoginOpen = () => {
    setLoginFormOpen(true);
  };

  const handleClose = () => {
    setLoginFormOpen(false);
    setError("");
    setEmail("");
    setPassword("");
  };

  const handleLoginManual = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Invalid email format");
      return;
    }

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential: UserCredential) => {
        handleClose();
        setIsLoggedIn(true);
        onLoginStatusChange(true);
        const user = userCredential.user;
        if (user) {
          setUsername(user.displayName || user.email || "");
        }
      })
      .catch((error: any) => {
        setError(error.message);
      });
  };

  const handleLoginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        handleClose();
        setIsLoggedIn(true);
        onLoginStatusChange(true);
      })
      .catch((error: any) => {
        setError(error.message);
      });
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsLoggedIn(false);
        onLoginStatusChange(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  if (isLoggedIn) {
    return (
      <>
        <Button
          variant="text"
          sx={{
            borderRadius: "20px",
            color: PrimaryColor,
            fontFamily: "productBold",
            textTransform: "none",
          }}
        >
          Welcome, {username}!
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          sx={{
            borderRadius: "20px",
            marginLeft: "10px",
            fontFamily: "productBold",
            textTransform: "none",
            background: PrimaryColor,
          }}
        >
          Logout
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          variant="text"
          sx={{
            borderRadius: "20px",
            color: PrimaryColor,
            fontFamily: "productBold",
            textTransform: "none",
          }}
          onClick={handleLoginOpen}
        >
          Log in
        </Button>
        <Dialog open={isLoginFormOpen} onClose={handleClose}>
          <Box sx={{ padding: "20px" }}>
            <DialogTitle style={{ textAlign: "center" }}>
              <Typography variant="h4" sx={{ fontFamily: "productBold" }}>
                Login
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleLoginManual}>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "productRegular" }}
                >
                  Email*
                </Typography>
                <TextField
                  label=""
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="standard"
                  inputProps={{ pattern: "^[^s@]+@[^s@]+.[^s@]+$" }}
                  sx={{
                    fontFamily: "productBold",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    },
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "productRegular", marginTop: "20px" }}
                >
                  Password*
                </Typography>
                <TextField
                  label=""
                  fullWidth
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="standard"
                  sx={{
                    fontFamily: "productBold",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    },
                  }}
                />
                <Box sx={{ marginTop: "10px", marginLeft: "-8px" }}>
                  <ForgotPassword />
                </Box>
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ fontFamily: "productRegular" }}
                  >
                    {error}
                  </Typography>
                )}
              </form>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleLoginManual}
                    sx={{
                      borderRadius: "20px",
                      width: "300px",
                      marginTop: "10px",
                      fontFamily: "productBold",
                      textTransform: "none",
                      background: PrimaryColor,
                    }}
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "productRegular", marginTop: "20px" }}
                  >
                    or log in with another method:
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleLoginWithGoogle}
                    sx={{
                      borderRadius: "20px",
                      width: "300px",
                      fontFamily: "productRegular",
                      textTransform: "none",
                      background: SecondaryColor,
                      "&:hover": {
                        background: "orange",
                      },
                      marginBottom: "20px",
                    }}
                  >
                    Log in with <Google sx={{ marginLeft: "10px" }} />
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
};

export default Login;
