import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { UserCredential } from "@firebase/auth-types";
import { PrimaryColor } from "../../app/AppThemeProvider";
import Login from "./Login";

const firebaseConfig = {
  apiKey: "AIzaSyDjtsmpZ0nPEDTOJaoxl0o71fFDWtwRQTA",
  authDomain: "adviceu-d5b58.firebaseapp.com",
  projectId: "adviceu-d5b58",
  storageBucket: "adviceu-d5b58.appspot.com",
  messagingSenderId: "495483461563",
  appId: "1:495483461563:web:5834022e462eea732a2e9e",
  measurementId: "G-NVB9ZM6L53",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Register: React.FC = () => {
  const [isRegisterFormOpen, setRegisterFormOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleRegisterOpen = () => {
    setRegisterFormOpen(true);
  };

  const handleClose = () => {
    setRegisterFormOpen(false);
    setError("");
    setName("");
    setEmail("");
    setPassword("");
    setUsername("");
    setConfirmPassword("");
  };

  const handleRegister = (event: React.FormEvent) => {
    event.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Invalid email format");
      return;
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential: UserCredential) => {
        const user = userCredential.user;
        if (user) {
          user
            .updateProfile({
              displayName: username,
            })
            .then(() => {
              handleClose();
              console.log("Registration success");
            })
            .catch((error: any) => {
              setError(error.message);
            });
        }
      })
      .catch((error: any) => {
        setError(error.message);
      });
  };

  const handleLoginStatus = (status: boolean) => {
    setRegisterFormOpen(false);
    setIsLoggedIn(status);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          borderRadius: "20px",
          color: "white",
          backgroundColor: PrimaryColor,
          fontFamily: "productBold",
          marginLeft: "20px",
          textTransform: "none",
        }}
        onClick={handleRegisterOpen}
      >
        Register
      </Button>
      <Dialog open={isRegisterFormOpen} onClose={handleClose}>
        <Box sx={{ padding: "20px" }}>
          <DialogTitle style={{ textAlign: "center" }}>
            <Typography variant="h4" sx={{ fontFamily: "productBold" }}>
              Register
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleRegister}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "productRegular", marginTop: "20px" }}
              >
                Name*
              </Typography>
              <TextField
                label=""
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="standard"
                sx={{
                  fontFamily: "productBold",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{ fontFamily: "productRegular", marginTop: "20px" }}
              >
                Email*
              </Typography>
              <TextField
                label=""
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="standard"
                inputProps={{ pattern: "^[^s@]+@[^s@]+.[^s@]+$" }}
                sx={{
                  fontFamily: "productBold",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{ fontFamily: "productRegular", marginTop: "20px" }}
              >
                Username*
              </Typography>
              <TextField
                label=""
                fullWidth
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="standard"
                sx={{
                  fontFamily: "productBold",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{ fontFamily: "productRegular", marginTop: "20px" }}
              >
                Password*
              </Typography>
              <TextField
                label=""
                fullWidth
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="standard"
                sx={{
                  fontFamily: "productBold",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{ fontFamily: "productRegular", marginTop: "20px" }}
              >
                Confirm Password*
              </Typography>
              <TextField
                label=""
                fullWidth
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="standard"
                sx={{
                  fontFamily: "productBold",
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              />
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ marginBottom: "10px" }}
                >
                  {error}
                </Typography>
              )}
            </form>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleRegister}
                  sx={{
                    borderRadius: "20px",
                    width: "300px",
                    marginTop: "10px",
                    fontFamily: "productBold",
                    textTransform: "none",
                    background: PrimaryColor,
                  }}
                >
                  Register
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "productRegular", marginTop: "20px" }}
                >
                  already have an account?
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Login onLoginStatusChange={handleLoginStatus} />
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default Register;
