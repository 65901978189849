import React from "react";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import { Box, Grid, Typography } from "@mui/material";
import { useIsDesktop } from "../../utils/useIsDesktop";
import "../../fonts/fonts.css";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";

export default function Cards() {
  return (
    <Grid container sx={containerStyle}>
      <Grid container spacing={2} sx={gridStyle}>
        <Grid item xs={12}>
          <Typography
            variant={useIsDesktop() ? "h3" : "h4"}
            sx={{
              fontFamily: "productBold",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            Why{" "}
            <Typography
              variant={useIsDesktop() ? "h3" : "h4"}
              sx={{
                fontFamily: "productBold",
                textAlign: "center",
                marginBottom: "40px",
                color: PrimaryColor,
              }}
              display="inline"
            >
              {" "}
              AdviceU?
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={useIsDesktop() ? 4 : 12}>
          <Box sx={[boxStyle, boxOneColor]}>
            <Box sx={[fileTopStyle, boxOneColor]}></Box>
            <Grid container>
              <Grid item xs={4} sx={{ marginLeft: "10%", marginTop: "40px" }}>
                <LocalPlayIcon
                  sx={{ color: "#FFFFFF", width: "80px", height: "80px" }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: "10%" }}>
                <Typography
                  variant="h3"
                  sx={{ color: "#FFFFFF", fontFamily: "productBold" }}
                >
                  200+
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontFamily: "productRegular" }}
                >
                  Mentors
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={useIsDesktop() ? 4 : 12}>
          <Box sx={[boxStyle, boxTwoColor]}>
            <Box sx={[fileTopStyle, boxTwoColor]}></Box>
            <Grid container>
              <Grid item xs={4} sx={{ marginLeft: "10%", marginTop: "40px" }}>
                <EmojiEventsIcon sx={{ width: "80px", height: "80px" }} />
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: "10%" }}>
                <Typography variant="h3" sx={{ fontFamily: "productBold" }}>
                  99%
                </Typography>
                <Typography variant="h5" sx={{ fontFamily: "productRegular" }}>
                  Success Rate
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={useIsDesktop() ? 4 : 12}>
          <Box sx={[boxStyle, boxThreeColor]}>
            <Box sx={[fileTopStyle, boxThreeColor]}></Box>
            <Grid container>
              <Grid item xs={4} sx={{ marginLeft: "10%", marginTop: "40px" }}>
                <StarIcon sx={{ width: "80px", height: "80px" }} />
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: "10%" }}>
                <Typography variant="h3" sx={{ fontFamily: "productBold" }}>
                  5 Star
                </Typography>
                <Typography variant="h5" sx={{ fontFamily: "productRegular" }}>
                  Services
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "30px 166px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "1500px",
  alignContent: "space-between",
  paddingBottom: "20px",
};

const boxStyle = {
  position: "relative",
  borderRadius: "20px",
  width: "100%",
  height: "250px",
  marginBottom: "20px",
};

const fileTopStyle = {
  position: "absolute",
  top: "-20px",
  left: "50%",
  transform: "translateX(-50%)",
  width: { xs: "250px", md: "150px" },
  height: "30px",
  borderRadius: "5px",
};

const boxOneColor = {
  background: "#191B24",
};

const boxTwoColor = {
  background: "#D4B5A1",
};

const boxThreeColor = {
  background: "#AFB7FB",
};
