import { BackgroundColor } from "../AppThemeProvider";
import { Grid } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CompanyBanner from "./CompanyBanner";
import StudentBanner from "./StudentBanner";
import MentorBanner from "./MentorBanner";
//import Testimonials from "./testimonials/Testimonials";

export default function HomePage() {
  return (
    <div>
      <Grid sx={{ bgcolor: BackgroundColor }}>
        <Header />
      </Grid>
      <CompanyBanner />
      <StudentBanner />
      <MentorBanner />
      <Footer />
    </div>
  );
}
