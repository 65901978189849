import { BackgroundColor } from "../AppThemeProvider";
import { Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PricingBox from "./PricingBox";

import "../../fonts/fonts.css";
import { useIsDesktop } from "../../utils/useIsDesktop";

export default function Pricing() {
  return (
    <div style={{ background: BackgroundColor }}>
      <Grid sx={{ bgcolor: BackgroundColor }}>
        <Header />
      </Grid>
      <Grid
        container
        spacing={4}
        sx={{
          padding: {
            xs: "0px 20px 10px",
            md: "20px 80px 30px",
          },
          textAlign: "center",
          marginBottom: "40px",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography
            variant={useIsDesktop() ? "h2" : "h3"}
            sx={{ fontFamily: "productBold" }}
          >
            Powerful alone.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "-30px" }}>
          <Typography
            variant={useIsDesktop() ? "h2" : "h3"}
            sx={{ fontFamily: "productBold" }}
          >
            Better together.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{
            marginTop: useIsDesktop() ? "-10px" : "-30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PricingBox tier={1} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{
            marginTop: "-10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PricingBox tier={2} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            marginTop: "-10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PricingBox tier={3} />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
