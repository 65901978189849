import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import logo from "../assets/logo_black.svg";
import { useIsDesktop } from "../utils/useIsDesktop";
import { PrimaryColor } from "../app/AppThemeProvider";
import "../fonts/fonts.css";
import Login from "./Login/Login";
import Register from "./Login/Register";

export default function Header() {
  const isDesktop = useIsDesktop();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTabClick = (event: any, newValue: any) => {
    setIsMenuOpen(false);
  };

  const handleLoginStatus = (status: boolean) => {
    setIsLoggedIn(status);
  };

  return (
    <div
      style={{
        paddingTop: "50px",
        paddingBottom: "5px",
        maxWidth: "1900px",
        margin: "0 auto",
      }}
    >
      <AppBar
        position="static"
        sx={{
          borderRadius: "15px",
          width: "80%",
          margin: "0 auto",
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link href="/">
            <img
              src={logo}
              alt=""
              style={{
                maxWidth: "154px",
                maxHeight: "77px",
                height: isDesktop ? "5vw" : "10vw",
                width: isDesktop ? "10vw" : "20vw",
                marginTop: isDesktop ? "10px" : "5px",
              }}
            />
          </Link>
          {isDesktop ? (
            <Tabs
              sx={{
                flexGrow: 1,
                "& .MuiTab-root": {
                  color: "black",
                  fontSize: "18px",
                  fontFamily: "productBold",
                  textTransform: "none",
                },
              }}
              centered
              value={false}
              onChange={handleTabClick}
            >
              <Tab
                label="Dashboard"
                href="/dashboard"
                sx={{ borderRadius: "20px" }}
              />
              <Tab
                label="For Students"
                href="/for_students"
                sx={{ borderRadius: "20px" }}
              />
              <Tab
                label="For Mentors"
                href="/for_mentors"
                sx={{ borderRadius: "20px" }}
              />
              <Tab
                label="Contact Us"
                href="/contact"
                sx={{ borderRadius: "20px" }}
              />
            </Tabs>
          ) : null}
          {isDesktop ? (
            <>
              <Login onLoginStatusChange={handleLoginStatus} />
              {!isLoggedIn && <Register />}
            </>
          ) : (
            <Button
              variant="contained"
              sx={{
                borderRadius: "20px",
                color: "white",
                backgroundColor: PrimaryColor,
                fontFamily: "productBold",
                textTransform: "none",
              }}
              onClick={toggleMenu}
            >
              Menu
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isDesktop ? null : (
        <Drawer
          anchor="top"
          open={isMenuOpen}
          onClose={toggleMenu}
          PaperProps={{
            sx: {
              backgroundColor: "#FFFFFF",
              padding: "20px",
            },
          }}
        >
          <List>
            <ListItem button component="a" href="/dashboard">
              <ListItemText
                primary="Dashboard"
                sx={{ fontWeight: "bold", fontFamily: "productBold" }}
              />
            </ListItem>
            <ListItem button component="a" href="/for_students">
              <ListItemText
                primary="For Students"
                sx={{ fontWeight: "bold", fontFamily: "productBold" }}
              />
            </ListItem>
            <ListItem button component="a" href="/for_mentors">
              <ListItemText
                primary="For Mentors"
                sx={{ fontWeight: "bold", fontFamily: "productBold" }}
              />
            </ListItem>
            <ListItem button component="a" href="/contact">
              <ListItemText
                primary="Contact Us"
                sx={{ fontWeight: "bold", fontFamily: "productBold" }}
              />
            </ListItem>
          </List>
          <Login onLoginStatusChange={handleLoginStatus} />
          {!isLoggedIn && <Register />}
        </Drawer>
      )}
    </div>
  );
}
