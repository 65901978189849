import { Box, Grid, Typography } from "@mui/material";
import WorkWithEditors from "../../assets/work_with_editors.svg";
import MatchingAlgorithm from "../../assets/matching_algorithm.svg";
import SuccessRate from "../../assets/success_rate.svg";
import VerifiedConsulting from "../../assets/verified_consulting.svg";
import OneOnOne from "../../assets/1_on_1.svg";
import { BackgroundColorLight } from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import "../../fonts/fonts.css";

export default function AboutUs() {
  return (
    <Grid
      container
      sx={{ bgcolor: BackgroundColorLight, justifyContent: "center" }}
    >
      <Grid sx={containerStyle}>
        <Grid container spacing={2} sx={gridStyle}>
          <Grid item xs={useIsDesktop() ? 6 : 12}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "productBold",
                lineHeight: "1.6",
              }}
            >
              Unlock your maximum university application success today.
            </Typography>
          </Grid>
          <Grid item xs={useIsDesktop() ? 6 : 12}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "productRegular",
                lineHeight: "2",
              }}
            >
              Looking for a successful university student to look over your
              application? Our team of dedicated Mentors are well-equipped to
              provide personalized guidance and support throughout the
              application process. From crafting compelling essays to securing
              strong recommendation letters, we have the expertise to ensure you
              stand out among the competition. Book a consultation today!
            </Typography>
          </Grid>
          <Grid
            item
            xs={useIsDesktop() ? 6 : 12}
            sx={{
              marginTop: { md: "30px", lg: "0px" },
              textAlign: "center",
            }}
          >
            <img
              src={WorkWithEditors}
              alt={""}
              style={{
                width: "auto",
                height: "auto",
                marginLeft: useIsDesktop() ? "-50px" : "0px",
                maxWidth: useIsDesktop() ? "570px" : "none",
              }}
            />
          </Grid>
          <Grid item xs={useIsDesktop() ? 6 : 12} sx={{ marginTop: "0px" }}>
            <Box sx={boxStyle}>
              <img src={VerifiedConsulting} alt={""} style={imgStyle} />
              <Typography sx={textStyle}>
                Verified Mentors with Track Records
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <img src={OneOnOne} alt={""} style={imgStyle} />
              <Typography sx={textStyle}>
                1 on 1 Meetings with Personalized Advice
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <img src={MatchingAlgorithm} alt={""} style={imgStyle} />
              <Typography sx={textStyle}>
                Individualized Mentor to Student Support
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <img src={SuccessRate} alt={""} style={imgStyle} />
              <Typography sx={textStyle}>
                Canada's Highest Success Rate
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 166px 30px" },
  direction: "column",
  justifyContent: "center",
  bgcolor: BackgroundColorLight,
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "1500px",
  alignContent: "space-between",
  paddingBottom: "20px",
};

const boxStyle = {
  bgcolor: "white",
  borderRadius: "20px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "20px",
  display: "flex",
  alignItems: "center",
  padding: "10px",
};

const imgStyle = {
  width: "50px",
  height: "50px",
};

const textStyle = {
  marginLeft: "15px",
  fontFamily: "productRegular",
};
