import { BackgroundColor } from "../AppThemeProvider";
import { Grid } from "@mui/material";
import HeaderStudent from "../../components/HeaderStudent";
import Footer from "../../components/Footer";
import ScrollingHeader from "../../components/scrollingHeader/ScrollingHeader";
import SBanner from "./SBanner";
import Cards from "./Cards";
import AboutUs from "./AboutUs";
import PricingBanner from "./PricingBanner";
import GetStarted from "./GetStarted";
import FAQ from "../faq/FAQ";

export default function SLandingPage() {
  return (
    <div>
      <Grid sx={{ bgcolor: BackgroundColor }}>
        <HeaderStudent />
      </Grid>
      <SBanner />
      <ScrollingHeader />
      <AboutUs />
      <Cards />
      <PricingBanner />
      <GetStarted />
      <FAQ data={"Student"} />
      <Footer />
    </div>
  );
}
