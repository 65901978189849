import React from "react";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import { Box, Grid, Typography } from "@mui/material";
import UniversityStudents from "../../assets/university-students.png";
import { useIsDesktop } from "../../utils/useIsDesktop";
import "../../fonts/fonts.css";
import Steps from "./Steps";

export default function SBanner() {
  return (
    <Grid container sx={containerStyle}>
      <Grid container spacing={2} sx={gridStyle}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            marginTop: useIsDesktop() ? "-100px" : "-70px",
          }}
        >
          <img
            src={UniversityStudents}
            style={{ width: useIsDesktop() ? "550px" : "100%" }}
            alt={""}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={useIsDesktop() ? "h2" : "h4"}
            sx={{
              fontWeight: "bold",
              fontFamily: "productRegular",
              textAlign: "center",
              marginTop: "-40px",
            }}
          >
            Need Help?
            <Box
              color={PrimaryColor}
              sx={{ fontFamily: "productRegular" }}
              display="inline"
            >
              {" "}
              AdviceU.
            </Box>
          </Typography>
          <Typography
            variant={useIsDesktop() ? "h4" : "h6"}
            sx={{
              fontFamily: "productRegular",
              textAlign: "center",
            }}
          >
            Getting into your dream university has never been so easy.
          </Typography>
        </Grid>
      </Grid>
      <Steps />
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 166px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "1500px",
  alignContent: "space-between",
  paddingBottom: "20px",
};
