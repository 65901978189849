import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface IAppThemeProviderProps {
  children: React.ReactNode;
}

export const PrimaryColor = "#3F43EB";
export const PrimaryColorDisabled = "#C5C6F9";
export const SecondaryColor = "#FC6759";
export const TertiaryColor = "#2B3449";
export const BackgroundColor = "#E1E8FD";
export const BackgroundColorLight = "#F8F8FF";

export default function AppThemeProvider(props: IAppThemeProviderProps) {
  const theme = createTheme({
    palette: {
      primary: {
        main: PrimaryColor,
      },
      action: {
        selectedOpacity: 0.1,
        disabledBackground: PrimaryColorDisabled,
        disabled: BackgroundColor,
      },
      background: {
        default: BackgroundColor,
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: PrimaryColor,
            },
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
