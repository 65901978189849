import { useIsDesktop } from "../../utils/useIsDesktop";
import { Box, Grid, Typography } from "@mui/material";
import { BackgroundColorLight } from "../AppThemeProvider";
import StudentCard from "./StudentCard";

export default function StudentBanner() {
  return (
    <Grid container sx={containerStyle}>
      <Grid
        container
        spacing={2}
        marginTop="20px"
        maxWidth="1200px"
        alignContent="space-between"
        paddingBottom="20px"
      >
        <Grid item xs={useIsDesktop() ? 6 : 12}>
          <Typography
            variant={useIsDesktop() ? "h3" : "h4"}
            sx={{ fontWeight: "bold" }}
          >
            Are You A High School Student?
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mt: "10px" }}>
              Your University Application Solution for Success!
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mt: "20px" }}>
            Seeking assistance with your university applications? Look no
            further! We are here to provide you with expert guidance from
            current university students. Our team is dedicated to helping you
            craft exceptional application materials and navigate the admissions
            process.
          </Typography>
          <Typography variant="body1" sx={{ mt: "15px" }}>
            Let us help you realize your academic aspirations and create
            unforgettable memories in your dream school. Don't hesitate,
            register today for a meeting with a mentor to kickstart your
            university journey with confidence!
          </Typography>
        </Grid>
        <Grid
          item
          xs={useIsDesktop() ? 6 : 12}
          sx={{
            textAlign: "left",
            marginTop: useIsDesktop() ? "0px" : "20px",
            marginBottom: useIsDesktop() ? "50px" : "0px",
          }}
        >
          <StudentCard />
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 96px 30px" },
  direction: "column",
  bgcolor: BackgroundColorLight,
  justifyContent: "center",
};
