import React from "react";
import "./styles.css";
import { BackgroundColorLight } from "../../app/AppThemeProvider";
import { Box, Typography } from "@mui/material";
import { useIsDesktop } from "../../utils/useIsDesktop";
import Waterloo from "../../assets/universityImages/Waterloo.png";
import Toronto from "../../assets/universityImages/Toronto.png";
import Queens from "../../assets/universityImages/Queens.png";
import McMaster from "../../assets/universityImages/McMaster.png";
import Ivey from "../../assets/universityImages/Ivey.png";
import UBC from "../../assets/universityImages/UBC.png";
import McGill from "../../assets/universityImages/McGill.png";
import Western from "../../assets/universityImages/Western.png";

const items = [Waterloo, Toronto, Queens, McMaster, Ivey, UBC, McGill, Western];

const ScrollingHeader = () => {
  return (
    <div>
      <Box
        sx={{
          width: "auto",
          height: "30px",
          background: BackgroundColorLight,
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Typography
          variant={useIsDesktop() ? "h6" : "body1"}
          sx={{ fontFamily: "productBold" }}
        >
          Work with Mentors from top universities
        </Typography>
      </Box>
      <div className="marquee-container">
        <div className="marquee" style={{ padding: "20px" }}>
          {items.map((item, index) => (
            <img
              src={item}
              key={index}
              style={{
                maxWidth: "210px",
                maxHeight: "70px",
                marginRight: "100px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollingHeader;
