import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MentorCard from "./MentorCard";
import { Box, Grid, TextField, Button, Link } from "@mui/material";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import logo from "../../assets/logo_black.svg";
import firebase from "../../render/firebase";

type Mentor = {
  age: number;
  description: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  location: string;
  picture: string;
  program: string;
  rating: {
    user: string;
    review: string;
    value: number;
  }[];
  students: {
    email: string;
    package: string;
    datetime: firebase.firestore.Timestamp;
  }[];
  university: string;
  year_of_study: string;
};

export default function Mentors() {
  const [currentLinkContent, setCurrentLinkContent] = useState("");
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [filters, setFilters] = useState({
    university: "",
    program: "",
    age: "",
    keywords: "",
  });
  const refMentors = firebase.firestore().collection("mentors");

  function getMentors() {
    refMentors.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
      const items: Mentor[] = querySnapshot.docs.map(
        (doc: any) => doc.data() as Mentor
      );
      setMentors(items);
    });
  }

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const contentAfterMentors = currentPathname.split("/mentors/")[1];
    setCurrentLinkContent(contentAfterMentors || "");
    getMentors();
  }, []);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      university: currentLinkContent,
    }));
  }, [currentLinkContent]);

  const calculateAverageRating = (ratings: any) => {
    if (ratings.length === 0) return 0;
    const totalRating = ratings.reduce(
      (sum: any, rating: any) => sum + rating.value,
      0
    );
    return totalRating / ratings.length;
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      university: "",
      program: "",
      age: "",
      keywords: "",
    });
  };

  const filteredMentors = mentors
    .filter((mentor) => {
      if (!mentor) {
        return false;
      }

      const universityMatch = mentor.university
        .toLocaleLowerCase()
        .includes(filters.university.toLocaleLowerCase());
      const programMatch = mentor.program
        .toLowerCase()
        .includes(filters.program.toLowerCase());
      const ageMatch = mentor.age.toString().includes(filters.age);
      const keywordsMatch = mentor.description
        .toLowerCase()
        .includes(filters.keywords.toLowerCase());

      return universityMatch && programMatch && ageMatch && keywordsMatch;
    })
    .sort((a, b) => {
      const aAverageRating = calculateAverageRating(a.rating);
      const bAverageRating = calculateAverageRating(b.rating);

      return bAverageRating - aAverageRating;
    });

  return (
    <div>
      <Box
        sx={{ bgcolor: BackgroundColor, height: "auto", minHeight: "100vh" }}
      >
        <Box
          sx={{
            marginLeft: useIsDesktop() ? "15vw" : "22vw",
            width: useIsDesktop() ? "90vw" : "80vw",
          }}
        >
          <Header />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box sx={sideBarStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Link href="/">
                    <img
                      src={logo}
                      alt=""
                      style={{
                        height: useIsDesktop() ? "7vw" : "9vw",
                        width: useIsDesktop() ? "15vw" : "20vw",
                        marginTop: useIsDesktop() ? "10px" : "5px",
                      }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="University"
                    name="university"
                    value={filters.university}
                    onChange={handleFilterChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    sx={{
                      fontFamily: "productRegular",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Program"
                    name="program"
                    value={filters.program}
                    onChange={handleFilterChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    sx={{
                      fontFamily: "productRegular",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Age"
                    name="age"
                    value={filters.age}
                    onChange={handleFilterChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    sx={{
                      fontFamily: "productRegular",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Keywords"
                    name="keywords"
                    value={filters.keywords}
                    onChange={handleFilterChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    sx={{
                      fontFamily: "productRegular",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={clearFilters}
                    fullWidth
                    sx={{
                      bgcolor: PrimaryColor,
                      fontFamily: "productBold",
                      marginTop: "10px",
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              marginLeft: useIsDesktop() ? "3vw" : "6vw",
              marginBottom: useIsDesktop() ? "100px" : "50px",
              marginTop: "20px",
            }}
          >
            <Grid container spacing={12}>
              {filteredMentors.map((mentor) => (
                <Grid key={mentor.first_name} item xs={12} sm={6} lg={4}>
                  {mentor && <MentorCard mentor={mentor} />}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginLeft: useIsDesktop() ? "20vw" : "25vw", width: "80vw" }}>
        <Footer />
      </Box>
    </div>
  );
}

const sideBarStyle = {
  p: 2,
  borderRight: "1px solid #ccc",
  bgcolor: "white",
  width: "20%",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
};
