import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Rating,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import useFetchEmail from "../../utils/useFetchEmail";
import useFetchUsername from "../../utils/useFetchUsername";
import firebase from "../../render/firebase";

type Mentor = {
  age: number;
  description: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  location: string;
  picture: string;
  program: string;
  rating: {
    user: string;
    review: string;
    value: number;
  }[];
  students: {
    email: string;
    package: string;
    datetime: firebase.firestore.Timestamp;
  }[];
  university: string;
  year_of_study: string;
};

export default function LeaveReview() {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [mentorEmail, setMentorEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const user_email = useFetchEmail();
  const [userEmail, setUserEmail] = useState(user_email);
  const user_name = useFetchUsername();
  const [username, setUsername] = useState(user_name);
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const refMentors = firebase.firestore().collection("mentors");

  function getMentors() {
    refMentors.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
      const items: Mentor[] = querySnapshot.docs.map(
        (doc: any) => doc.data() as Mentor
      );
      setMentors(items);
    });
  }

  useEffect(() => {
    setUserEmail(user_email);
  }, [user_email]);

  useEffect(() => {
    setUsername(user_name);
  }, [user_name]);

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const mentorEmailFromURL = pathSegments[2];
    const firstNameFromURL = pathSegments[3];
    const lastNameFromURL = pathSegments[4];

    setMentorEmail(mentorEmailFromURL);
    setFirstName(firstNameFromURL);
    setLastName(lastNameFromURL);

    getMentors();
  }, []);

  function countWords(text: string): number {
    return text.trim().split(/\s+/).length;
  }

  const handleReviewTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputText = event.target.value;
    if (countWords(inputText) <= 50) {
      setReview(inputText);
    }
  };

  function findMentorByEmail(email: string): Mentor | undefined {
    return mentors.find((mentor) => mentor.email === email);
  }

  function isUserEmailInMentorStudentsArray(
    mentor: Mentor | undefined,
    userEmail: string
  ): boolean {
    if (!mentor) return false;
    return mentor.students.some((student) => student.email === userEmail);
  }

  const handleRatingChange = (event: any) => {
    setRating(parseInt(event.target.value));
  };

  const handleReviewChange = (event: any) => {
    setReview(event.target.value);
  };

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  function addReview() {
    const mentor = findMentorByEmail(mentorEmail);

    if (!mentor) {
      console.error("Mentor not found!");
      setErrorSnackbarOpen(true);
      return;
    }

    if (!isUserEmailInMentorStudentsArray(mentor, userEmail)) {
      console.error("You are not a student of this mentor!");
      setErrorSnackbarOpen(true);
      return;
    }

    const updatedRating = {
      user: username,
      review: review,
      value: rating,
    };

    const updatedRatingArray = [...mentor.rating, updatedRating];

    refMentors
      .doc(mentor.id)
      .update({ rating: updatedRatingArray })
      .then(() => {
        console.log("Review added successfully!");
        setSuccessSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error adding review:", error);
        setErrorSnackbarOpen(true);
      });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (countWords(review) > 50) {
      console.error("Review should be 50 words or less.");
      setErrorSnackbarOpen(true);
      return;
    }

    addReview();

    setRating(0);
    setReview("");
  };

  return (
    <div style={{ backgroundColor: BackgroundColor }}>
      <Header />
      <div
        style={{
          padding: useIsDesktop() ? "80px 0" : "30px 30px",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "40px",
              }}
            >
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontFamily: "productBold",
                  marginBottom: "20px",
                }}
              >
                Give Feedback
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{
                  fontFamily: "productRegular",
                  marginBottom: "20px",
                }}
              >
                Want to share your thoughts and experiences with your mentor?
                Leave a review!
              </Typography>
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "productBold", marginBottom: "5px" }}
                >
                  How did {firstName} {lastName} do?*
                </Typography>
                <Box display="flex" alignItems="center">
                  <Rating
                    name="rating"
                    value={rating}
                    onChange={handleRatingChange}
                    precision={1}
                  />
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "productBold",
                    marginBottom: "5px",
                    marginTop: "20px",
                  }}
                >
                  Please include some details about your experience!*
                </Typography>
                <TextField
                  label=""
                  required
                  variant="outlined"
                  fullWidth
                  value={review}
                  onChange={handleReviewTextChange}
                  multiline
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "productRegular",
                    color: "grey",
                    marginBottom: "20px",
                  }}
                >
                  {countWords(review)}/50 words
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontFamily: "productBold", background: PrimaryColor }}
                >
                  Publish Feedback
                </Button>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "productRegular",
                    marginTop: "10px",
                    color: "grey",
                  }}
                >
                  Your review will be posted on {firstName}'s booking page
                </Typography>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        message="Review added successfully!"
      />
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setErrorSnackbarOpen(false)}
        message="Error adding review. Please try again later."
      />
    </div>
  );
}
