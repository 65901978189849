import React, { useState } from "react";
import schedule from "../../data/calendar/calendar";
import { Button, Grid, Typography, List, Box } from "@mui/material";
import { PrimaryColor } from "../AppThemeProvider";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

type TimeSlots = string[];

type ScheduleData = {
  name: string;
  calendar: {
    [month: string]: {
      [day: string]: TimeSlots;
    };
  };
};

type CalendarProps = {
  name: string;
  selectedTimeSlot: string;
  onTimeSlotSelection: (timeSlot: string) => void;
  selectedDate: string;
  onDateSelection: (month: string, day: string) => void;
};

const CalendarComponent: React.FC<CalendarProps> = ({
  name,
  selectedTimeSlot,
  onTimeSlotSelection,
  selectedDate,
  onDateSelection,
}) => {
  const [selectedDayTimeSlots, setSelectedDayTimeSlots] = useState<TimeSlots>(
    []
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<string>("");

  const personSchedule: ScheduleData | undefined = schedule.find(
    (person: ScheduleData) => person.name === name
  );

  if (!personSchedule) {
    return (
      <div>
        It seems this person does not exist, or does not work here! Hmmm...
      </div>
    );
  }

  const months: string[] = Object.keys(personSchedule.calendar);

  const getDaysInMonth = (month: string): string[] => {
    return Object.keys(personSchedule.calendar[month]);
  };

  const handleDayClick = (month: string, day: string) => {
    setSelectedMonth(months.indexOf(month));
    setSelectedDay(day);
    setSelectedDayTimeSlots(personSchedule.calendar[month][day]);
    onDateSelection(month, day);
    onTimeSlotSelection(personSchedule.calendar[month][day][0]);
  };

  const handleTimeSlotClick = (timeSlot: string) => {
    onTimeSlotSelection(timeSlot);
  };

  const renderCalendar = (): JSX.Element[] => {
    const calendar: JSX.Element[] = [];

    const month = months[selectedMonth];
    const daysInMonth = getDaysInMonth(month);

    for (const day of daysInMonth) {
      const timeSlots: TimeSlots = personSchedule.calendar[month][day];

      const isSelectedDay =
        selectedMonth === months.indexOf(month) && selectedDay === day;
      calendar.push(
        <Button
          key={`${month}-${day}`}
          onClick={() => handleDayClick(month, day)}
          variant={isSelectedDay ? "contained" : "text"}
          sx={{
            borderRadius: "360px",
            fontFamily: "productBold",
            color: isSelectedDay ? "#FFFFFF" : "#000000",
            background: isSelectedDay ? PrimaryColor : "none",
          }}
        >
          {`${day}`}
        </Button>
      );
    }

    return calendar;
  };

  const handleNextMonth = () => {
    const nextMonthIndex = (selectedMonth + 1) % months.length;
    setSelectedMonth(nextMonthIndex);
    setSelectedDay("");
    setSelectedDayTimeSlots([]);
    onTimeSlotSelection("");
  };

  const handlePrevMonth = () => {
    const prevMonthIndex =
      selectedMonth === 0 ? months.length - 1 : selectedMonth - 1;
    setSelectedMonth(prevMonthIndex);
    setSelectedDay("");
    setSelectedDayTimeSlots([]);
    onTimeSlotSelection("");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <Typography sx={{ fontFamily: "productBold", mb: "10px" }}>
          Select date and time
        </Typography>
        <Box sx={boxStyle}>
          <Grid container sx={{ alignContent: "center" }}>
            <Grid item xs={3}>
              <Button
                variant="text"
                sx={{ color: "black" }}
                onClick={handlePrevMonth}
              >
                <ArrowBack />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" sx={{ fontFamily: "productBold" }}>
                {months[selectedMonth]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="text"
                sx={{ color: "black" }}
                onClick={handleNextMonth}
              >
                <ArrowForward />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={calendarStyle}>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {renderCalendar().map((button) => (
              <Grid key={button.key} item>
                {button}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6} sx={{ ml: ["0px", "0px", "0px", "20px"] }}>
        {selectedDay ? (
          <Typography sx={{ fontFamily: "productBold", mb: "10px" }}>
            {months[selectedMonth]} {[selectedDay]},{" "}
            {months[selectedMonth] === "December" ? 2023 : 2024}
          </Typography>
        ) : (
          <Typography sx={{ fontFamily: "productBold", mb: "10px" }}>
            {months[selectedMonth]},{" "}
            {months[selectedMonth] === "December" ? 2023 : 2024}
          </Typography>
        )}
        {selectedDayTimeSlots.length > 0 && (
          <Grid container spacing={2} sx={{ mt: "10px" }}>
            {selectedDayTimeSlots.map((timeSlot) => (
              <Grid item xs={6} md={4}>
                <Button
                  key={timeSlot}
                  variant="contained"
                  onClick={() => handleTimeSlotClick(timeSlot)}
                  sx={{
                    fontFamily: "productBold",
                    background:
                      selectedTimeSlot === timeSlot ? PrimaryColor : "#FFFFFF",
                    color:
                      selectedTimeSlot === timeSlot ? "#FFFFFF" : "#000000",
                    width: "170px",
                  }}
                >
                  {timeSlot}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CalendarComponent;

const boxStyle = {
  background: "#FFFFFF",
  borderRadius: "10px",
  padding: "10px",
  textAlign: "center",
};

const calendarStyle = {
  background: "#FFFFFF",
  borderRadius: "10px",
  padding: "20px",
  mt: "10px",
};
