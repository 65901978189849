import React, { useRef } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useIsDesktop } from "../../utils/useIsDesktop";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactPage: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!form.current) {
      return;
    }

    const formData = new FormData(form.current);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const user_email = formData.get("user_email") as string;

    if (!user_email.match(emailRegex)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const data = {
      to_name: "AdviceU",
      from_name: formData.get("user_name") as string,
      from_email: user_email,
      message: formData.get("message") as string,
    };

    emailjs
      .send("service_9l4gqfq", "template_yu4543d", data, "B_p5ogARWU_e95i3y")
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
          toast.success("Email sent successfully!");
          clearForm();
        },
        (error) => {
          console.error("Error sending email:", error.text);
          toast.error("Error sending email. Please try again later.");
        }
      );
  };

  const clearForm = () => {
    if (!form.current) {
      return;
    }

    form.current.reset();
  };

  return (
    <div style={{ backgroundColor: BackgroundColor }}>
      <Header />
      <div
        style={{
          padding: useIsDesktop() ? "80px 0" : "30px 30px",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "40px",
              }}
            >
              <form ref={form} onSubmit={sendEmail}>
                <Typography
                  variant="h2"
                  align="center"
                  sx={{
                    fontFamily: "productBold",
                    marginBottom: "20px",
                  }}
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontFamily: "productRegular",
                    marginBottom: "40px",
                  }}
                >
                  Have questions? Send us a message, and we'll get back to you
                  shortly.
                </Typography>
                <TextField
                  label="Name"
                  fullWidth
                  required
                  name="user_name"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Email"
                  fullWidth
                  required
                  name="user_email"
                  type="email"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Message"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  name="message"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <Grid container justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "20px",
                      width: "200px",
                      marginTop: "20px",
                      fontFamily: "productRegular",
                      background: PrimaryColor,
                      textTransform: "none",
                    }}
                  >
                    Send Message
                  </Button>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default ContactPage;
