import { Box, Grid, Typography, Stack } from "@mui/material";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import {
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
} from "@mui/lab";
import { ArrowForward } from "@mui/icons-material";

export const checkpoints = [
  {
    id: 1,
    date: "Today",
    item: "Apply to be a Mentor",
  },
  {
    id: 2,
    date: "Today",
    item: "Wait to be booked",
  },
  {
    id: 3,
    date: "Scheduled by student",
    item: "You've been booked!",
  },
  {
    id: 4,
    date: "Day 1",
    item: "Send email to student",
  },
  {
    id: 5,
    date: "Day 2",
    item: "Receive application",
  },
  {
    id: 6,
    date: "Day 3",
    item: "Send meeting link",
  },
  {
    id: 7,
    date: "Scheduled by student",
    item: "Attend meeting or email edited application",
  },
  {
    id: 8,
    date: "Until deadline",
    item: "Post-Application email follow ups",
  },
];

const CustomDivider = () => (
  <TimelineSeparator>
    <ArrowForward
      sx={{
        color: PrimaryColor,
      }}
    />
    <TimelineConnector />
  </TimelineSeparator>
);

const Timeline = () => {
  return (
    <Grid container sx={containerStyle}>
      <Grid
        container
        spacing={2}
        marginTop="20px"
        maxWidth="1200px"
        alignContent="center"
        paddingBottom="20px"
      >
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontFamily: "productBold" }}>
            Timeline
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: "20px" }}>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Stack
              direction="row"
              spacing={2}
              divider={<CustomDivider />}
              alignItems="center"
            >
              {checkpoints.map((checkpoint) => (
                <TimelineItem key={checkpoint.id}>
                  <TimelineContent>
                    <Stack spacing={1} sx={{ width: "150px" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "text.secondary",
                          fontFamily: "productBold",
                        }}
                      >
                        {checkpoint.date}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontFamily: "productBold" }}
                      >
                        {checkpoint.item}
                      </Typography>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Timeline;

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 96px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};
