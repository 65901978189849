import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const useFetchEmail = () => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const email = user.email || "";
        setEmail(email);
      } else {
        setEmail("");
      }
    });

    return () => unsubscribe();
  }, []);

  return email;
};

export default useFetchEmail;
