import React from "react";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useIsDesktop } from "../../utils/useIsDesktop";

export default function MBanner() {
  return (
    <Grid container sx={containerStyle}>
      <Grid container spacing={2} sx={gridStyle}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "productBold",
              textAlign: "center",
            }}
          >
            Student-to-Student consulting is the future.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "productRegular",
              textAlign: "center",
            }}
          >
            You share the facts. It's time to let students share the experience.
            AdviceU is reimagining how students apply.
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <Button variant="contained" sx={buttonStyle} href="/apply">
            Join the team
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 166px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "800px",
  alignContent: "space-between",
  paddingBottom: "20px",
};

const buttonStyle = {
  borderRadius: "10px",
  fontFamily: "productBold",
  background: PrimaryColor,
  marginTop: "10px",
};
