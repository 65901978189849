import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { Box, Grid, Button, Link, Typography } from "@mui/material";
import {
  BackgroundColor,
  PrimaryColor,
  SecondaryColor,
} from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import logo from "../../assets/logo_black.svg";
import useFetchUsername from "../../utils/useFetchUsername";
import useFetchEmail from "../../utils/useFetchEmail";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import PlaylistAddCheckRoundedIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import ThumbsUpDownRoundedIcon from "@mui/icons-material/ThumbsUpDownRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ApprovalRoundedIcon from "@mui/icons-material/ApprovalRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
import firebase from "../../render/firebase";

type Mentor = {
  age: number;
  description: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  location: string;
  picture: string;
  program: string;
  rating: {
    user: string;
    review: string;
    value: number;
  }[];
  students: {
    email: string;
    package: string;
    datetime: firebase.firestore.Timestamp;
  }[];
  university: string;
  year_of_study: string;
};

type Student = {
  email: string;
  mentors: {
    email: string;
    package: string;
    datetime: firebase.firestore.Timestamp;
  }[];
};

export default function Dashboard() {
  const username = useFetchUsername();
  const email = useFetchEmail();
  const [selectedButton, setSelectedButton] = useState("mentors");
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [students, setStudents] = useState<Student[]>([]);

  const refMentors = firebase.firestore().collection("mentors");
  const refStudents = firebase.firestore().collection("students");
  const isDesktop = useIsDesktop();

  let mentorIndex = 0;
  let studentIndex = 0;

  const handleButtonClick = (buttonName: any) => {
    setSelectedButton(buttonName);
  };

  function isMentor(mentor: any) {
    if (mentor.email === email) {
      mentorIndex++;
      return true;
    } else {
      return false;
    }
  }

  function isStudent(student: any) {
    if (student.email === email) {
      studentIndex++;
      return true;
    } else {
      return false;
    }
  }

  function printMessage() {
    if (selectedButton === "mentors") {
      if (mentorIndex === 0) {
        mentorIndex++;
        return (
          <>
            <Typography
              variant="h5"
              sx={{ fontFamily: "productRegular", mt: "20px" }}
            >
              Hmm... looks like you haven't yet signed up to be a mentor.
            </Typography>
            <Button
              variant="contained"
              href="/apply"
              sx={{
                fontFamily: "productBold",
                mt: "10px",
                background: PrimaryColor,
                textTransform: "none",
              }}
            >
              Apply Now
            </Button>
          </>
        );
      } else {
        return null;
      }
    } else {
      if (studentIndex === 0) {
        studentIndex++;
        return (
          <>
            <Typography
              variant="h5"
              sx={{ fontFamily: "productRegular", mt: "20px" }}
            >
              Hmm... looks like you haven't yet booked your first package as a
              student.
            </Typography>
            <Button
              variant="contained"
              href="/mentors"
              sx={{
                fontFamily: "productBold",
                mt: "10px",
                background: PrimaryColor,
                textTransform: "none",
              }}
            >
              Choose A Mentor
            </Button>
          </>
        );
      } else {
        return null;
      }
    }
  }

  function getEarnings(mentor: Mentor) {
    let earnings = 0;
    const currentDate = new Date();

    mentor.students.forEach((student) => {
      if (student.datetime.toDate() < currentDate) {
        if (student.package === "Application Edits") {
          earnings += 30;
        } else if (student.package === "60 Min Meeting") {
          earnings += 50;
        } else {
          earnings += 100;
        }
      }
    });

    return earnings;
  }

  function getEarliestDatetimeMentor(mentor: Mentor): Date | string {
    if (!mentor.students || mentor.students.length === 0) {
      return "No Upcoming Deadlines";
    }

    const today = new Date();
    let earliestDatetime: Date | string = "";

    for (let i = 0; i < mentor.students.length; i++) {
      const currentDatetime = mentor.students[i].datetime.toDate();
      if (
        currentDatetime > today &&
        (earliestDatetime === "" || currentDatetime < earliestDatetime)
      ) {
        earliestDatetime = currentDatetime;
      }
    }

    return earliestDatetime;
  }

  function getEarliestDatetimeStudent(student: Student): Date | string {
    if (!student.mentors || student.mentors.length === 0) {
      return "No Upcoming Deadlines";
    }

    const today = new Date();
    let earliestDatetime: Date | string = "";

    for (let i = 0; i < student.mentors.length; i++) {
      const currentDatetime = student.mentors[i].datetime.toDate();
      if (
        currentDatetime > today &&
        (earliestDatetime === "" || currentDatetime < earliestDatetime)
      ) {
        earliestDatetime = currentDatetime;
      }
    }

    return earliestDatetime;
  }

  function sortStudentsByDatetime(mentor: Mentor): Mentor {
    const sortedStudents = mentor.students.slice().sort((a, b) => {
      const datetimeA = a.datetime.toDate();
      const datetimeB = b.datetime.toDate();

      return datetimeA.getTime() - datetimeB.getTime();
    });

    return { ...mentor, students: sortedStudents };
  }

  function sortMentorsByDatetime(student: Student): Student {
    const sortedMentors = student.mentors.slice().sort((a, b) => {
      const datetimeA = a.datetime.toDate();
      const datetimeB = b.datetime.toDate();

      return datetimeA.getTime() - datetimeB.getTime();
    });

    return { ...student, mentors: sortedMentors };
  }

  function renderDatesMentor(mentor: Mentor) {
    const len = mentor.students.length;
    const Elements: JSX.Element[] = [];

    const today = new Date();

    for (let i = 0; i < len; i++) {
      const studentDate = mentor.students[i].datetime.toDate();

      const isBeforeToday = studentDate < today;
      const IconComponent = isBeforeToday ? (
        <PlaylistAddCheckRoundedIcon sx={{ color: "green" }} />
      ) : (
        <PlaylistAddRoundedIcon sx={{ color: "red" }} />
      );

      if (mentor.students[i].package === "Application Edits") {
        Elements.push(
          <Grid container alignItems="center" key={i}>
            <Grid item>{IconComponent}</Grid>
            <Grid item sx={{ ml: ["0px", "0px", "15px"] }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "productRegular",
                  marginBottom: ["20px", "20px", "5px"],
                }}
              >
                Application for {mentor.students[i].email} due by{" "}
                {studentDate.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        );
      } else {
        Elements.push(
          <Grid container alignItems="center" key={i}>
            <Grid item>{IconComponent}</Grid>
            <Grid item sx={{ ml: ["0px", "0px", "15px"] }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "productRegular",
                  marginBottom: ["20px", "20px", "5px"],
                }}
              >
                Meeting with {mentor.students[i].email} on{" "}
                {studentDate.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
    return Elements.length > 0 ? (
      Elements
    ) : (
      <Typography variant="h6" sx={{ fontFamily: "productRegular" }}>
        There's nothing here... yet!
      </Typography>
    );
  }

  function renderDatesStudent(student: Student) {
    const len = student.mentors.length;
    const Elements: JSX.Element[] = [];

    const today = new Date();

    for (let i = 0; i < len; i++) {
      const mentorDate = student.mentors[i].datetime.toDate();

      const isBeforeToday = mentorDate < today;
      const IconComponent = isBeforeToday ? (
        <PlaylistAddCheckRoundedIcon sx={{ color: "green" }} />
      ) : (
        <PlaylistAddRoundedIcon sx={{ color: "red" }} />
      );

      if (student.mentors[i].package === "Application Edits") {
        Elements.push(
          <Grid container alignItems="center" key={i}>
            <Grid item>{IconComponent}</Grid>
            <Grid item sx={{ ml: ["0px", "0px", "15px"] }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "productRegular",
                  marginBottom: ["20px", "20px", "5px"],
                }}
              >
                Application reviewed by {student.mentors[i].email} will be sent
                to you by {mentorDate.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        );
      } else {
        Elements.push(
          <Grid container alignItems="center" key={i}>
            <Grid item>{IconComponent}</Grid>
            <Grid item sx={{ ml: ["0px", "0px", "15px"] }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "productRegular",
                  marginBottom: ["20px", "20px", "5px"],
                }}
              >
                Meeting with {student.mentors[i].email} on{" "}
                {mentorDate.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }

    return Elements.length > 0 ? (
      Elements
    ) : (
      <Typography variant="h6" sx={{ fontFamily: "productRegular" }}>
        There's nothing here... yet!
      </Typography>
    );
  }

  function getAverageRating(mentor: Mentor) {
    if (!mentor.rating || mentor.rating.length === 0) {
      return (
        <Typography variant="h6" sx={{ fontFamily: "productBold" }}>
          No Ratings
        </Typography>
      );
    }

    let totalRating = 0;
    const len = mentor.rating.length;

    for (let i = 0; i < len; i++) {
      totalRating += mentor.rating[i].value;
    }

    const averageRating = totalRating / len;
    const Elements: JSX.Element[] = [];
    for (let x = Math.floor(averageRating); x >= 1; x--) {
      Elements.push(
        <StarRateRoundedIcon sx={{ width: "40px", height: "40px" }} />
      );
    }
    if (averageRating % 1 !== 0) {
      Elements.push(
        <StarHalfRoundedIcon sx={{ width: "40px", height: "40px" }} />
      );
    }
    return Elements;
  }

  function getNextApplication(
    student: Student
  ): firebase.firestore.Timestamp | string {
    const desiredPackage = "Application Edits";
    const currentTime = firebase.firestore.Timestamp.fromDate(new Date());

    const mentorsWithPackage = student.mentors.filter(
      (mentor) =>
        mentor.package === desiredPackage && mentor.datetime > currentTime
    );

    if (mentorsWithPackage.length === 0) {
      return "No edits in progress.";
    }

    mentorsWithPackage.sort((a, b) => a.datetime.seconds - b.datetime.seconds);

    const nextMentorDatetime = mentorsWithPackage[0].datetime;

    return nextMentorDatetime;
  }

  function getMentors() {
    refMentors.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
      const items: Mentor[] = querySnapshot.docs.map(
        (doc: any) => doc.data() as Mentor
      );
      setMentors(items);
    });
  }

  function getStudents() {
    refStudents.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        const items: Student[] = querySnapshot.docs.map(
          (doc: any) => doc.data() as Student
        );
        setStudents(items);
      }
    );
  }

  useEffect(() => {
    getMentors();
    getStudents();
  });

  return (
    <div>
      <Box
        sx={{
          bgcolor: BackgroundColor,
          height: "auto",
          minHeight: "100vh",
          paddingBottom: "100px",
        }}
      >
        <Box
          sx={{
            marginLeft: useIsDesktop() ? "20vw" : "22vw",
            width: useIsDesktop() ? "80vw" : "80vw",
            height: "80px",
            background: "#FFFFFF",
          }}
        >
          <Grid
            container
            sx={{
              direction: "row",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              height: "80px",
              marginLeft: "100px",
            }}
          >
            <Grid item xs={12}>
              <Button
                onClick={() => handleButtonClick("mentors")}
                sx={{
                  marginBottom: "-35px",
                  fontFamily: "productBold",
                  color:
                    selectedButton === "mentors" ? PrimaryColor : "#000000",
                }}
              >
                Mentors
              </Button>
              <Button
                onClick={() => handleButtonClick("students")}
                sx={{
                  marginLeft: "50px",
                  marginBottom: "-35px",
                  fontFamily: "productBold",
                  color:
                    selectedButton === "students" ? PrimaryColor : "#000000",
                }}
              >
                Students
              </Button>
            </Grid>
            <Grid item xs={12}>
              {selectedButton === "mentors" ? (
                <Box
                  sx={{
                    height: "3px",
                    width: "90px",
                    marginLeft: "-3px",
                    background: PrimaryColor,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: "3px",
                    width: "90px",
                    marginLeft: "135px",
                    background: PrimaryColor,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box sx={sideBarStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Link href="/">
                    <img
                      src={logo}
                      alt=""
                      style={{
                        height: useIsDesktop() ? "7vw" : "9vw",
                        width: useIsDesktop() ? "15vw" : "20vw",
                        marginTop: useIsDesktop() ? "10px" : "5px",
                      }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sx={sideBarGridStyle}>
                  <Button href="/" sx={{ textTransform: "none", gap: "15px" }}>
                    <HomeRoundedIcon sx={sideBarIconStyle} />
                    <Typography variant="h6" sx={sideBarTagStyle}>
                      {useIsDesktop() ? "Home" : ""}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sx={sideBarGridStyle}>
                  <Button
                    href="/apply"
                    sx={{ textTransform: "none", gap: "15px" }}
                  >
                    <ApprovalRoundedIcon sx={sideBarIconStyle} />
                    <Typography variant="h6" sx={sideBarTagStyle}>
                      {useIsDesktop() ? "Apply" : ""}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sx={sideBarGridStyle}>
                  <Button
                    href="/mentors"
                    sx={{ textTransform: "none", gap: "15px" }}
                  >
                    <PersonAddAlt1RoundedIcon sx={sideBarIconStyle} />
                    <Typography variant="h6" sx={sideBarTagStyle}>
                      {useIsDesktop() ? "Mentors" : ""}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sx={sideBarGridStyle}>
                  <Button
                    href="/pricing"
                    sx={{ textTransform: "none", gap: "15px" }}
                  >
                    <AttachMoneyRoundedIcon sx={sideBarIconStyle} />
                    <Typography variant="h6" sx={sideBarTagStyle}>
                      {useIsDesktop() ? "Pricing" : ""}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sx={sideBarGridStyle}>
                  <Button
                    href="/contact"
                    sx={{ textTransform: "none", gap: "15px" }}
                  >
                    <PermPhoneMsgRoundedIcon sx={sideBarIconStyle} />
                    <Typography variant="h6" sx={sideBarTagStyle}>
                      {useIsDesktop() ? "Contact Us" : ""}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              marginLeft: useIsDesktop() ? "20px" : "6vw",
              marginTop: "80px",
            }}
          >
            {email === "" ? (
              <Typography
                variant="h5"
                sx={{ fontFamily: "productBold", mt: "5px" }}
              >
                Please log in to view analytics!
              </Typography>
            ) : (
              <>
                <Typography variant="h4" sx={{ fontFamily: "productBold" }}>
                  Hi {username}! Here Are Your{" "}
                  {selectedButton === "mentors" ? "Mentor" : "Student"}{" "}
                  Analytics
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "productRegular", color: "grey" }}
                >
                  Your current email: {email}
                </Typography>
                {selectedButton === "mentors" ? (
                  <>
                    {mentors.map((mentor) =>
                      isMentor(mentor) ? (
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            sx={{ marginTop: "40px" }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                height: "120px",
                                width: ["200px", "200px", "250px"],
                                padding: "20px",
                              }}
                            >
                              <PaidRoundedIcon
                                sx={{
                                  color: SecondaryColor,
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontFamily: "productRegular", mt: "5px" }}
                              >
                                Career Earnings
                              </Typography>

                              <Typography
                                variant="h4"
                                sx={{ fontFamily: "productBold" }}
                              >
                                $
                                {mentors.map(
                                  (mentor) =>
                                    `${
                                      mentor.email === email
                                        ? getEarnings(mentor)
                                        : ""
                                    }`
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            sx={{ marginTop: "40px" }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                height: "120px",
                                width: ["200px", "200px", "250px"],
                                padding: "20px",
                              }}
                            >
                              <GroupsRoundedIcon
                                sx={{
                                  color: SecondaryColor,
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontFamily: "productRegular", mt: "5px" }}
                              >
                                Next Meeting
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{ fontFamily: "productBold" }}
                              >
                                {mentors.map(
                                  (mentor) =>
                                    `${
                                      mentor.email === email
                                        ? getEarliestDatetimeMentor(
                                            mentor
                                          )?.toLocaleString()
                                        : ""
                                    }`
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            sx={{ marginTop: "40px" }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                height: "120px",
                                width: ["200px", "200px", "250px"],
                                padding: "20px",
                              }}
                            >
                              <ThumbsUpDownRoundedIcon
                                sx={{
                                  color: SecondaryColor,
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontFamily: "productRegular", mt: "5px" }}
                              >
                                Mentor Rating
                              </Typography>
                              {mentors.map((mentor) => (
                                <>
                                  {mentor.email === email
                                    ? getAverageRating(mentor)
                                    : null}
                                </>
                              ))}
                            </Box>
                          </Grid>
                          {isDesktop ? (
                            <>
                              <Grid
                                item
                                md={12}
                                lg={6}
                                sx={{ marginTop: "20px" }}
                              >
                                <Box
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "20px",
                                    height: "240px",
                                    width: ["58vw", "58vw", "58vw", "28vw"],
                                    padding: "20px",
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={6} sx={{ marginTop: "20px" }}>
                                <Box
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "20px",
                                    height: "240px",
                                    width: ["58vw", "58vw", "58vw", "28vw"],
                                    padding: "20px",
                                  }}
                                ></Box>
                              </Grid>
                            </>
                          ) : null}
                          <Grid item xs={12} sx={{ marginTop: "20px" }}>
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                width: ["50vw", "50vw", "58vw", "62vw", "60vw"],
                                padding: "20px",
                              }}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  fontFamily: "productBold",
                                  marginBottom: "10px",
                                }}
                              >
                                Your Schedule
                              </Typography>
                              {mentors.map((mentor) => (
                                <>
                                  {mentor.email === email
                                    ? renderDatesMentor(
                                        sortStudentsByDatetime(mentor)
                                      )
                                    : null}
                                </>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null
                    )}
                    {printMessage()}
                  </>
                ) : (
                  <>
                    {students.map((student) =>
                      isStudent(student) ? (
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            sx={{ marginTop: "40px" }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                height: "120px",
                                width: ["200px", "200px", "250px"],
                                padding: "20px",
                              }}
                            >
                              <MenuBookRoundedIcon
                                sx={{
                                  color: SecondaryColor,
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontFamily: "productRegular", mt: "5px" }}
                              >
                                Application Return Date
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{ fontFamily: "productBold" }}
                              >
                                {students.map(
                                  (student) =>
                                    `${
                                      student.email === email
                                        ? getNextApplication(
                                            student
                                          )?.toLocaleString()
                                        : ""
                                    }`
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            sx={{ marginTop: "40px" }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                height: "120px",
                                width: ["200px", "200px", "250px"],
                                padding: "20px",
                              }}
                            >
                              <GroupsRoundedIcon
                                sx={{
                                  color: SecondaryColor,
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontFamily: "productRegular", mt: "5px" }}
                              >
                                Next Meeting
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{ fontFamily: "productBold" }}
                              >
                                {students.map(
                                  (student) =>
                                    `${
                                      student.email === email
                                        ? getEarliestDatetimeStudent(
                                            student
                                          )?.toLocaleString()
                                        : ""
                                    }`
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            sx={{ marginTop: "40px" }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                height: "120px",
                                width: ["200px", "200px", "250px"],
                                padding: "20px",
                              }}
                            >
                              <HourglassTopRoundedIcon
                                sx={{
                                  color: SecondaryColor,
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontFamily: "productRegular", mt: "5px" }}
                              >
                                Find A Mentor
                              </Typography>

                              <Button
                                variant="contained"
                                href="/mentors"
                                sx={{
                                  fontFamily: "productBold",
                                  mt: "5px",
                                  textTransform: "none",
                                  borderRadius: "10px",
                                  background: "#000000",
                                  "&:hover": {
                                    background: SecondaryColor,
                                  },
                                }}
                              >
                                Book Now
                              </Button>
                            </Box>
                          </Grid>
                          {isDesktop ? (
                            <>
                              <Grid
                                item
                                md={12}
                                lg={6}
                                sx={{ marginTop: "20px" }}
                              >
                                <Box
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "20px",
                                    height: "240px",
                                    width: ["58vw", "58vw", "58vw", "28vw"],
                                    padding: "20px",
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={6} sx={{ marginTop: "20px" }}>
                                <Box
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "20px",
                                    height: "240px",
                                    width: ["58vw", "58vw", "58vw", "28vw"],
                                    padding: "20px",
                                  }}
                                ></Box>
                              </Grid>
                            </>
                          ) : null}
                          <Grid item xs={12} sx={{ marginTop: "20px" }}>
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "20px",
                                width: ["50vw", "50vw", "58vw", "62vw", "60vw"],
                                padding: "20px",
                              }}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  fontFamily: "productBold",
                                  marginBottom: "10px",
                                }}
                              >
                                Your Schedule
                              </Typography>
                              {students.map((student) => (
                                <>
                                  {student.email === email
                                    ? renderDatesStudent(
                                        sortMentorsByDatetime(student)
                                      )
                                    : null}
                                </>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null
                    )}
                    {printMessage()}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginLeft: useIsDesktop() ? "20vw" : "25vw", width: "80vw" }}>
        <Footer />
      </Box>
    </div>
  );
}

const sideBarStyle = {
  p: 2,
  borderRight: "1px solid #ccc",
  bgcolor: "white",
  width: "20%",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
};

const sideBarTagStyle = {
  fontFamily: "productRegular",
  color: "grey",
};

const sideBarGridStyle = {
  display: "flex",
  gap: "20px",
  textAlign: "center",
  marginLeft: "8px",
};

const sideBarIconStyle = {
  width: ["40px", "40px", "30px"],
  height: ["40px", "40px", "30px"],
  color: "grey",
};
