import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { PrimaryColor, SecondaryColor } from "../AppThemeProvider";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import firebase from "../../render/firebase";

type Mentor = {
  age: number;
  description: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  location: string;
  picture: string;
  program: string;
  rating: {
    user: string;
    review: string;
    value: number;
  }[];
  students: {
    email: string;
    package: string;
    datetime: firebase.firestore.Timestamp;
  }[];
  university: string;
  year_of_study: string;
};

type MentorCardProps = {
  mentor: Mentor;
};

export default function ViewProfileCard(props: MentorCardProps) {
  const [sortOrder, setSortOrder] = useState<"highest" | "lowest">("highest");
  const [showMoreRatings, setShowMoreRatings] = useState(false);
  const MAX_VISIBLE_RATINGS = 3;

  function getAverageRating(mentor: Mentor) {
    if (!mentor.rating || mentor.rating.length === 0) {
      return (
        <Typography
          variant="body1"
          sx={{ fontFamily: "productBold", marginTop: "10px" }}
        >
          No Ratings
        </Typography>
      );
    }

    let totalRating = 0;
    const len = mentor.rating.length;

    for (let i = 0; i < len; i++) {
      totalRating += mentor.rating[i].value;
    }

    const averageRating = totalRating / len;
    const Elements: JSX.Element[] = [];
    for (let x = Math.floor(averageRating); x >= 1; x--) {
      Elements.push(
        <StarRateRoundedIcon
          sx={{ width: "40px", height: "40px", color: "#D4AF37" }}
        />
      );
    }
    if (averageRating % 1 !== 0) {
      Elements.push(
        <StarHalfRoundedIcon
          sx={{ width: "40px", height: "40px", color: "#D4AF37" }}
        />
      );
    }
    return Elements;
  }

  function renderStars(value: number) {
    const Elements: JSX.Element[] = [];
    for (let x = Math.floor(value); x >= 1; x--) {
      Elements.push(
        <StarRateRoundedIcon
          sx={{ width: "20px", height: "20px", color: "#D4AF37" }}
        />
      );
    }
    if (value % 1 !== 0) {
      Elements.push(
        <StarHalfRoundedIcon
          sx={{ width: "20px", height: "20px", color: "#D4AF37" }}
        />
      );
    }
    return Elements;
  }

  function sortRatings(mentor: Mentor) {
    return [...mentor.rating].sort((a, b) =>
      sortOrder === "highest" ? b.value - a.value : a.value - b.value
    );
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <DialogTitle style={{ textAlign: "center" }}>
        <img src={props.mentor.picture} alt={""} style={dialogImgStyle} />
        <Typography variant="h4" sx={{ fontFamily: "productBold", mt: "10px" }}>
          {props.mentor.first_name} {props.mentor.last_name}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Typography
          sx={{
            fontFamily: "productRegular",
            fontSize: "16px",
            color: "grey",
          }}
        >
          {props.mentor.age} years old
        </Typography>
        <Typography
          sx={{
            fontFamily: "productRegular",
            fontSize: "16px",
            color: "grey",
          }}
        >
          {props.mentor.location}
        </Typography>
        <Typography
          sx={{
            fontFamily: "productRegular",
            fontSize: "16px",
            color: "grey",
          }}
        >
          {props.mentor.university}
        </Typography>
        <Typography
          sx={{
            fontFamily: "productRegular",
            fontSize: "16px",
            color: "grey",
          }}
        >
          {props.mentor.year_of_study} {props.mentor.program}
        </Typography>
        {getAverageRating(props.mentor)}
        <Typography
          sx={{
            fontFamily: "productBold",
            fontSize: "15px",
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          About me:
        </Typography>
        <Typography
          sx={{
            fontFamily: "productRegular",
            fontSize: "15px",
            textAlign: "left",
          }}
        >
          {props.mentor.description}
        </Typography>
        {props.mentor.rating.length > 0 && (
          <Typography
            sx={{
              fontFamily: "productBold",
              fontSize: "15px",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            Reviews:
          </Typography>
        )}
        {sortRatings(props.mentor)
          .slice(
            0,
            showMoreRatings ? props.mentor.rating.length : MAX_VISIBLE_RATINGS
          )
          .map((rating, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography sx={{ marginTop: "15px", marginLeft: "-5px" }}>
                {renderStars(rating.value)}
              </Typography>
              <Typography sx={{ fontFamily: "productBold", textAlign: "left" }}>
                {rating.user}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "productRegular",
                  textAlign: "left",
                  color: "#555555",
                }}
              >
                {rating.review}
              </Typography>
            </div>
          ))}
        {props.mentor.rating.length > MAX_VISIBLE_RATINGS && (
          <Button
            variant="text"
            color="primary"
            onClick={() => setShowMoreRatings(!showMoreRatings)}
            sx={{
              textTransform: "none",
              mt: 1,
              fontFamily: "productBold",
              color: PrimaryColor,
            }}
          >
            {showMoreRatings ? "Show Less Ratings" : "Show More Ratings"}
          </Button>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              href={`/review/${props.mentor.email}/${props.mentor.first_name}/${props.mentor.last_name}`}
              sx={{
                borderRadius: "20px",
                width: "300px",
                marginTop: "10px",
                fontFamily: "productBold",
                textTransform: "none",
                background: SecondaryColor,
                "&:hover": {
                  background: "orange",
                },
              }}
            >
              Leave a Review for {props.mentor.first_name}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              href={`/booking/${props.mentor.first_name} ${props.mentor.last_name}/${props.mentor.university}/${props.mentor.program}`}
              sx={{
                borderRadius: "20px",
                width: "300px",
                marginTop: "10px",
                fontFamily: "productBold",
                textTransform: "none",
                background: PrimaryColor,
              }}
            >
              Book Me!
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
}

const dialogImgStyle = {
  width: "200px",
  height: "200px",
  borderRadius: "360px",
  boxShadow: "0 0 0 5px white",
};
