import { Box, Button, Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "../../fonts/fonts.css";
import { PrimaryColor } from "../AppThemeProvider";

interface IPricingBoxProps {
  tier: number;
}

export default function PricingBox(props: IPricingBoxProps) {
  return (
    <Box sx={boxStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "productBold",
              marginBottom: "-10px",
              marginTop: "30px",
            }}
          >
            {props.tier === 1
              ? "Application Edits"
              : props.tier === 2
              ? "60 Min Meeting"
              : "Personal Mentor"}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ display: "flex", marginTop: "10px" }}
        >
          <Grid item>
            <Typography variant="h3" sx={{ fontFamily: "productBold" }}>
              {props.tier === 1 ? "$29" : props.tier === 2 ? "$49" : "$99"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={{ fontFamily: "productBold" }}>
              /{" "}
              {props.tier === 1
                ? "mentor"
                : props.tier === 2
                ? "mentor"
                : "mentor"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div>
              {props.tier === 1 ? (
                <div>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Expertly polished application edits
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Optimized to match university criteria.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Grammatical and spelling corrections.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        60-minute virtual meeting with mentor.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Tailored guidance for needs & goals.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Ask questions to experienced mentors.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Insight into campus life and ecs.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Unlimited email support until school start.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Regular progress checks with mentor.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ) : props.tier === 2 ? (
                <div>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Expertly polished application edits
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Optimized to match university criteria.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Grammatical and spelling corrections.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        60-minute virtual meeting with mentor.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Tailored guidance for needs & goals.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Ask questions to experienced mentors.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Insight into campus life and ecs.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Unlimited email support until school start.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CloseIcon sx={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "grey" }}
                      >
                        Regular progress checks with mentor.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Expertly polished application edits
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Optimized to match university criteria.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Grammatical and spelling corrections.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        60-minute virtual meeting with mentor.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Tailored guidance for needs & goals.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Ask questions to experienced mentors.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Insight into campus life and ecs.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Unlimited email support until school start.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={gridStyle}>
                    <Grid item>
                      <CheckIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "productRegular", color: "black" }}
                      >
                        Regular progress checks with mentor.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "40px" }}>
          {props.tier === 1 ? (
            <Button href="/booking" variant="contained" sx={buttonStyle}>
              Purchase
            </Button>
          ) : props.tier === 2 ? (
            <Button href="/booking" variant="contained" sx={buttonStyle}>
              Purchase
            </Button>
          ) : (
            <Button href="/booking" variant="contained" sx={buttonStyle}>
              Purchase
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const boxStyle = {
  bgcolor: "#FFFFFF",
  borderRadius: "20px",
  marginTop: "50px",
  width: "390px",
};

const gridStyle = {
  display: "flex",
  marginLeft: "30px",
  marginBottom: "10px",
};

const buttonStyle = {
  backgroundColor: PrimaryColor,
  width: "200px",
  height: "50px",
  borderRadius: 15,
  fontFamily: "productBold",
};
