import { useIsDesktop } from "../../utils/useIsDesktop";
import { Box, Button, Grid, Typography } from "@mui/material";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import MentorEarning from "../../assets/MentorEarning.png";

export default function MentorBanner() {
  return (
    <Grid container sx={containerStyle}>
      <Grid
        container
        spacing={2}
        marginTop="20px"
        maxWidth="1200px"
        alignContent="space-between"
        paddingBottom="20px"
      >
        <Grid
          item
          xs={useIsDesktop() ? 6 : 12}
          sx={{
            textAlign: "left",
            marginTop: { xs: "0px", sm: "80px", lg: "0px" },
          }}
        >
          <img
            src={MentorEarning}
            alt=""
            style={{
              width: useIsDesktop() ? "40vw" : "90vw",
              maxWidth: "500px",
            }}
          />
        </Grid>
        <Grid item xs={useIsDesktop() ? 6 : 12}>
          <Typography
            variant={useIsDesktop() ? "h3" : "h4"}
            sx={{ fontWeight: "bold" }}
          >
            Are You A University Student?
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: "10px" }}>
            <Button variant="outlined" sx={buttonStyle} href="/for_mentors">
              Learn More
            </Button>
          </Box>
          <Typography variant="body1" sx={{ mt: "20px" }}>
            Are you a university student passionate about helping others succeed
            in their applications? Join our community of mentors and make a
            meaningful impact on the next generation of applicants. Your
            knowledge and experience are invaluable assets that can guide
            aspiring students towards achieving their academic dreams.
          </Typography>
          <Typography variant="body1" sx={{ mt: "15px" }}>
            Join our team, connect with fellow mentors, and embark on a
            rewarding journey of mentorship and self-discovery. Sign up today to
            start making a difference and earning money!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 96px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};

const buttonStyle = {
  borderRadius: "20px",
  fontFamily: "productBold",
  textTransform: "none",
  bakground: PrimaryColor,
};
