import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const useFetchUsername = () => {
  const [username, setUsername] = useState("");

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const username = user.displayName || user.email || "";
        setUsername(username);
      } else {
        setUsername("");
      }
    });

    return () => unsubscribe();
  }, []);

  return username;
};

export default useFetchUsername;
