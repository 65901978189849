import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Box,
} from "@mui/material";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useIsDesktop } from "../../utils/useIsDesktop";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import universities from "./universites";
import useFetchEmail from "../../utils/useFetchEmail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const applyData = [
  {
    question: "Where can I find my public URL?",
    answer: `Please navigate to your LinkedIn profile and employ the right-click action on your profile picture. 
    You will encounter an option to copy the image address. Kindly select this option and subsequently insert the copied 
    link into the designated field above. In the event that you do not possess a LinkedIn account, you may avail the services 
    of an online image-to-link conversion tool. Prior to implementation, it is advisable to confirm the functionality of the provided link.`,
  },
];

const Apply: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const user_email = useFetchEmail();
  const [email, setEmail] = useState(user_email);
  const [aboutMe, setAboutMe] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    setEmail(user_email);
  }, [user_email]);

  const handleAboutMeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);
    const wordCount = words.length;
    if (wordCount <= 130) {
      setAboutMe(inputText);
      setWordCount(wordCount);
    }
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!form.current) {
      return;
    }

    const formData = new FormData(form.current);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const user_email = formData.get("user_email") as string;

    if (!user_email.match(emailRegex)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const data = {
      to_name: "AdviceU",
      user_image: formData.get("user_image") as string,
      from_name: formData.get("user_first_name") as string,
      from_last_name: formData.get("user_last_name") as string,
      from_age: formData.get("user_age") as string,
      from_university: formData.get("user_university") as string,
      from_program: formData.get("user_program") as string,
      from_year_of_study: formData.get("user_year_of_study") as string,
      from_location: formData.get("user_location") as string,
      from_linkedin: formData.get("user_linkedin") as string,
      user_schedule: formData.get("user_schedule") as string,
      message: formData.get("message") as string,
    };

    emailjs
      .send("service_9l4gqfq", "template_7eg5uzp", data, "B_p5ogARWU_e95i3y")
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
          toast.success("Email sent successfully!");
          clearForm();
        },
        (error) => {
          console.error("Error sending email:", error.text);
          toast.error("Error sending email. Please try again later.");
        }
      );
  };

  const handleExpand = (index: any) => {
    setExpanded(expanded === index ? null : index);
  };

  const clearForm = () => {
    if (!form.current) {
      return;
    }
    form.current.reset();
  };

  return (
    <div style={{ backgroundColor: BackgroundColor }}>
      <Header />
      <div
        style={{
          padding: useIsDesktop() ? "80px 0" : "30px 30px",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "40px",
              }}
            >
              <form ref={form} onSubmit={sendEmail}>
                <Typography
                  variant="h2"
                  align="center"
                  sx={{
                    fontFamily: "productBold",
                    marginBottom: "20px",
                  }}
                >
                  Apply Now!
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontFamily: "productRegular",
                    marginBottom: "40px",
                  }}
                >
                  Let us know why you should be on the team.
                </Typography>
                <TextField
                  label="Public URL to Profile Picture"
                  fullWidth
                  required
                  name="user_image"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                {applyData.map((item, index) => (
                  <Box key={index}>
                    <Typography
                      variant={useIsDesktop() ? "h6" : "body1"}
                      onClick={() => handleExpand(index)}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom:
                          expanded === index ? "0px" : "1px solid #e0e0e0",
                        padding: "0px 0px 20px",
                        fontFamily:
                          expanded === index ? "productBold" : "productRegular",
                      }}
                    >
                      {item.question}
                      <ExpandMoreIcon
                        sx={{
                          transform:
                            expanded === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                      />
                    </Typography>
                    {expanded === index && (
                      <Typography
                        variant="body1"
                        sx={{
                          borderBottom:
                            expanded === index ? "1px solid #e0e0e0" : "0px",
                          padding: "0px 0px 30px",
                          fontFamily: "productRegular",
                        }}
                      >
                        {item.answer}
                      </Typography>
                    )}
                  </Box>
                ))}
                <TextField
                  label="First Name"
                  fullWidth
                  required
                  name="user_first_name"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Last Name"
                  fullWidth
                  required
                  name="user_last_name"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                  name="user_email"
                  type="email"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Age"
                  fullWidth
                  required
                  name="user_age"
                  type="number"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <Autocomplete
                  options={universities}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="University"
                      fullWidth
                      required
                      name="user_university"
                      sx={{
                        marginBottom: "20px",
                        backgroundColor: "#F8F8FF",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                />
                <TextField
                  label="Program"
                  fullWidth
                  required
                  name="user_program"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Year of Study"
                  fullWidth
                  required
                  name="user_year_of_study"
                  type="number"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Location (ex. Toronto, ON, Canada)"
                  fullWidth
                  required
                  name="user_location"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="LinkedIn"
                  fullWidth
                  required
                  name="user_linkedin"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label="Provide as much detail as possible about your schedule from Dec 2023 - Jun 2024"
                  fullWidth
                  required
                  name="user_schedule"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  label='Write an "About Me" section from your point of view'
                  fullWidth
                  required
                  multiline
                  rows={4}
                  value={aboutMe}
                  onChange={handleAboutMeChange}
                  name="message"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#F8F8FF",
                    borderRadius: "8px",
                  }}
                />
                <Typography
                  variant="caption"
                  color={wordCount > 130 ? "error" : "textSecondary"}
                >
                  {wordCount}/130 words
                </Typography>
                <Grid container justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "20px",
                      width: "200px",
                      marginTop: "20px",
                      fontFamily: "productBold",
                      background: PrimaryColor,
                    }}
                  >
                    Send Message
                  </Button>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default Apply;
