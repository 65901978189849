import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../app/home/Home";
import Mentors from "../app/mentors/Mentors";
import Pricing from "../app/pricing/Pricing";
import ContactPage from "../app/contact/Contact";
import "firebase/auth";
import BookingPage from "../app/booking/BookingPage";
import Apply from "../app/apply/Apply";
import Dashboard from "../app/dashboard/Dashboard";
import LeaveReview from "../app/reviews/LeaveReview";
import MLandingPage from "../app/for-mentors/MLandingPage";
import SLandingPage from "../app/for-students/SLandingPage";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/mentors" component={Mentors} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/booking" component={BookingPage} />
        <Route path="/apply" component={Apply} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/review" component={LeaveReview} />
        <Route path="/for_mentors" component={MLandingPage} />
        <Route path="/for_students" component={SLandingPage} />
      </Switch>
    </Router>
  );
}
