import Waterloo from "../../assets/universityImages/Waterloo.png";
import Toronto from "../../assets/universityImages/Toronto.png";
import Queens from "../../assets/universityImages/Queens.png";
import McMaster from "../../assets/universityImages/McMaster.png";
import Western from "../../assets/universityImages/Western.png";
import UBC from "../../assets/universityImages/UBC.png";
import McGill from "../../assets/universityImages/McGill.png";

export interface University {
  name: string;
  image: string;
}

export const university: University[] = [
  {
    name: "Waterloo",
    image: Waterloo,
  },
  {
    name: "Toronto",
    image: Toronto,
  },
  {
    name: "Queens",
    image: Queens,
  },
  {
    name: "McMaster",
    image: McMaster,
  },
  {
    name: "Western",
    image: Western,
  },
  {
    name: "British Columbia",
    image: UBC,
  },
  {
    name: "McGill",
    image: McGill,
  },
];
