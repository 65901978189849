import React, { useState } from "react";
import { Box, Button, Grid, Typography, Dialog } from "@mui/material";
import { PrimaryColor, SecondaryColor } from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import ViewProfileCard from "./ViewProfileCard";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import firebase from "../../render/firebase";

type Mentor = {
  age: number;
  description: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  location: string;
  picture: string;
  program: string;
  rating: {
    user: string;
    review: string;
    value: number;
  }[];
  students: {
    email: string;
    package: string;
    datetime: firebase.firestore.Timestamp;
  }[];
  university: string;
  year_of_study: string;
};

type MentorCardProps = {
  mentor: Mentor;
};

const truncateText = (text: string, maxWords: number) => {
  const words = text.split(/\s+/);
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "...";
  }
  return text;
};

const MentorCard: React.FC<MentorCardProps> = ({ mentor }) => {
  function getAverageRating(mentor: Mentor) {
    if (!mentor.rating || mentor.rating.length === 0) {
      return (
        <Typography
          variant="body1"
          sx={{ fontFamily: "productBold", marginTop: "10px" }}
        >
          No Ratings
        </Typography>
      );
    }

    let totalRating = 0;
    const len = mentor.rating.length;

    for (let i = 0; i < len; i++) {
      totalRating += mentor.rating[i].value;
    }

    const averageRating = totalRating / len;
    const Elements: JSX.Element[] = [];
    for (let x = Math.floor(averageRating); x >= 1; x--) {
      Elements.push(
        <StarRateRoundedIcon
          sx={{ width: "40px", height: "40px", color: "#D4AF37" }}
        />
      );
    }
    if (averageRating % 1 !== 0) {
      Elements.push(
        <StarHalfRoundedIcon
          sx={{ width: "40px", height: "40px", color: "#D4AF37" }}
        />
      );
    }
    return Elements;
  }

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleViewProfileClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box sx={boxStyle} width={useIsDesktop() ? "300px" : "250px"} height="auto">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              bgcolor: PrimaryColor,
              borderRadius: "20px 20px 0 0",
              height: "50px",
            }}
          ></Box>
        </Grid>
        <Box sx={{ padding: "15px 20px 10px 30px" }}>
          <Grid item xs={12}>
            <img src={mentor.picture} alt={""} style={imgStyle} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontFamily: "productBold", fontSize: "26px" }}>
              {mentor.first_name} - {mentor.age}
            </Typography>
            <Typography
              sx={{
                fontFamily: "productRegular",
                fontSize: "16px",
                color: "grey",
              }}
            >
              {mentor.university}
            </Typography>
            <Typography
              sx={{
                fontFamily: "productRegular",
                fontSize: "16px",
                color: "grey",
              }}
            >
              {mentor.year_of_study} {mentor.program}
            </Typography>
            {getAverageRating(mentor)}
            <Typography
              sx={{
                fontFamily: "productRegular",
                fontSize: "15px",
                marginTop: "10px",
              }}
            >
              {truncateText(mentor.description, 30)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleViewProfileClick}
              sx={{ fontFamily: "productBold", color: SecondaryColor }}
            >
              View Profile
            </Button>
          </Grid>
        </Box>
      </Grid>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <ViewProfileCard mentor={mentor} />
      </Dialog>
    </Box>
  );
};

export default MentorCard;

const boxStyle = {
  bgcolor: "white",
  borderRadius: "20px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "20px",
  display: "flex",
  textAlign: "center",
};

const imgStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "360px",
  marginTop: "-45px",
  boxShadow: "0 0 0 5px white",
};
