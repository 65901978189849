import { Grid, Typography } from "@mui/material";
import { BackgroundColorLight } from "../AppThemeProvider";
import { useIsDesktop } from "../../utils/useIsDesktop";
import "../../fonts/fonts.css";
import PricingBox from "./PricingBox";

export default function PricingBanner() {
  return (
    <Grid
      container
      sx={{ bgcolor: BackgroundColorLight, justifyContent: "center" }}
    >
      <Grid sx={containerStyle}>
        <Grid container spacing={2} sx={gridStyle}>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography
              variant={useIsDesktop() ? "h2" : "h3"}
              sx={{ fontFamily: "productBold" }}
            >
              Powerful alone.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-30px" }}>
            <Typography
              variant={useIsDesktop() ? "h2" : "h3"}
              sx={{ fontFamily: "productBold" }}
            >
              Better together.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              marginTop: useIsDesktop() ? "-10px" : "-30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PricingBox tier={1} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              marginTop: "-10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PricingBox tier={2} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              marginTop: "-10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PricingBox tier={3} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "0px 50px 30px" },
  direction: "column",
  justifyContent: "center",
  bgcolor: BackgroundColorLight,
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "2000px",
  alignContent: "space-between",
  paddingBottom: "20px",
  textAlign: "center",
};
