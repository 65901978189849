import React, { useState } from "react";
import { useIsDesktop } from "../../utils/useIsDesktop";
import { Box, Grid, Typography } from "@mui/material";
import { BackgroundColor } from "../AppThemeProvider";
import SubmitQuestionCard from "./SubmitQuestionCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqDataStudent = [
  {
    question: "What qualifications do the mentors have?",
    answer: `Our mentors are carefully selected based on their academic achievements, leadership experiences, and their commitment to helping others succeed. They are high-achieving students from various universities who have a strong track record of academic excellence, extracurricular involvement, and a genuine passion for assisting fellow students in their journey to higher education.`,
  },
  {
    question: "How do I get matched with a mentor?",
    answer: `At our mentorship program, the process of getting matched with a mentor is uniquely empowering. You have the wonderful opportunity to select your very own mentor from a diverse pool of experienced individuals. This element of choice allows you to ensure compatibility and find a mentor who resonates with your goals and aspirations. Our platform offers an array of mentors, each bringing their own expertise and insights. By choosing the mentor who best aligns with your needs, you are setting the stage for a rewarding and personalized mentorship experience.`,
  },
  {
    question: "What kind of assistance can I expect from my mentor?",
    answer: `Depending on your seletced tier, your mentor will provide guidance on various aspects of the university application process, including but not limited to essay writing, extracurricular activity selection, recommendation letter tips, and interview preparation. They will review your application materials, offer constructive feedback, and share their personal experiences to help you craft a compelling and authentic application that stands out to admissions committees.`,
  },
  {
    question: "What do I get in each of the three tiers?",
    answer: `The "Application Edits" tier places your application under the expert guidance of one of our accomplished Mentors. Your application undergoes a meticulous review and meticulous editing process by our experienced professionals, ensuring its refinement and enhancement. Within a span of one week, you receive the meticulously revised application.
    
    The "60 Minute Meeting" tier offers a comprehensive experience through a one-hour, face-to-face consultation with a dedicated Mentor. This interactive session entails a thorough walkthrough of your application, meticulously dissecting each step. Furthermore, this platform grants you the opportunity to engage in an open dialogue, posing any questions that require clarification.
    
    For those seeking an elevated level of support, the "Personal Mentor" tier embodies a deeply individualized approach. With this tier, you are assigned a Mentor exclusively committed to advancing your objectives. This includes a singular 60-minute consultation, coupled with consistent email check-ins to ensure a continuous monitoring of your progress.`,
  },
];

const faqDataMentor = [
  {
    question: "How do mentorship sessions take place?",
    answer: `Mentorship sessions are conducted virtually, allowing for flexible and convenient interactions. Depending on the selected tier, sessions may include application material reviews, video consultations, and email check-ins. This digital approach enables mentors and students from diverse locations to connect seamlessly.`,
  },
  {
    question: "Is prior mentoring experience required?",
    answer: `While prior mentoring experience is beneficial, it's not a strict requirement. What matters most is your dedication, expertise, and genuine desire to help students. We provide resources and support to ensure you're well-prepared to provide effective mentorship.`,
  },
  {
    question: "How do I join as a mentor?",
    answer: `Becoming a mentor is easy. Simply sign up on our platform, create a mentor profile, and showcase your qualifications and areas of expertise. Once approved, you can start browsing student profiles, selecting mentees, and embarking on a rewarding mentorship journey.`,
  },
  {
    question: "Can I mentor multiple students?",
    answer: `Yes, depending on your availability and preference, you can choose to mentor multiple students. However, we encourage mentors to ensure that they can dedicate sufficient time and attention to each student they mentor to provide a valuable and impactful experience.`,
  },
];

interface FAQProps {
  data: string;
}

export default function FAQ(props: FAQProps) {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (index: any) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Grid container sx={containerStyle}>
      <Grid
        container
        spacing={2}
        marginTop="20px"
        maxWidth="1200px"
        alignContent="space-between"
        paddingBottom="20px"
      >
        <Grid item xs={useIsDesktop() ? 6 : 12}>
          <Typography
            variant={useIsDesktop() ? "h3" : "h4"}
            sx={{ fontFamily: "productBold" }}
          >
            Frequently Asked Questions
          </Typography>
          <Box
            sx={{
              textAlign: "left",
              mt: "20px",
              width: useIsDesktop() ? "90%" : "100%",
            }}
          >
            {props.data === "Mentor" ? (
              <>
                {faqDataMentor.map((item, index) => (
                  <Box key={index}>
                    <Typography
                      variant={useIsDesktop() ? "h6" : "body1"}
                      onClick={() => handleExpand(index)}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom:
                          expanded === index ? "0px" : "1px solid #e0e0e0",
                        padding: "12px 0",
                        fontFamily:
                          expanded === index ? "productBold" : "productRegular",
                      }}
                    >
                      {item.question}
                      <ExpandMoreIcon
                        sx={{
                          transform:
                            expanded === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                      />
                    </Typography>
                    {expanded === index && (
                      <Typography
                        variant="body1"
                        sx={{
                          borderBottom:
                            expanded === index ? "1px solid #e0e0e0" : "0px",
                          padding: "0px 0px 12px",
                          fontFamily: "productRegular",
                        }}
                      >
                        {item.answer.split("\n").map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    )}
                  </Box>
                ))}
              </>
            ) : (
              <>
                {faqDataStudent.map((item, index) => (
                  <Box key={index}>
                    <Typography
                      variant={useIsDesktop() ? "h6" : "body1"}
                      onClick={() => handleExpand(index)}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom:
                          expanded === index ? "0px" : "1px solid #e0e0e0",
                        padding: "12px 0",
                        fontFamily:
                          expanded === index ? "productBold" : "productRegular",
                      }}
                    >
                      {item.question}
                      <ExpandMoreIcon
                        sx={{
                          transform:
                            expanded === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                      />
                    </Typography>
                    {expanded === index && (
                      <Typography
                        variant="body1"
                        sx={{
                          borderBottom:
                            expanded === index ? "1px solid #e0e0e0" : "0px",
                          padding: "0px 0px 12px",
                          fontFamily: "productRegular",
                        }}
                      >
                        {item.answer.split("\n").map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    )}
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={useIsDesktop() ? 6 : 12}
          sx={{ textAlign: "left", marginTop: useIsDesktop() ? "0px" : "20px" }}
        >
          <SubmitQuestionCard />
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 96px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};
