import React from "react";
import { BackgroundColor, PrimaryColor } from "../AppThemeProvider";
import { Box, Button, Grid, Typography } from "@mui/material";
import ApplicationHelp from "../../assets/application_help.svg";
import { useIsDesktop } from "../../utils/useIsDesktop";
import { ArrowForward } from "@mui/icons-material";
import "../../fonts/fonts.css";

export default function CompanyBanner() {
  return (
    <Grid container sx={containerStyle}>
      <Grid container spacing={2} sx={gridStyle}>
        <Grid
          item
          xs={useIsDesktop() ? 6 : 12}
          sx={{
            marginTop: { xs: "0px", md: "40px", xl: "100px" },
          }}
        >
          <Typography
            variant={useIsDesktop() ? "h2" : "h4"}
            sx={{
              fontWeight: "bold",
              fontFamily: "productRegular",
            }}
          >
            We are
            <Box
              color={PrimaryColor}
              sx={{ fontFamily: "productRegular" }}
              display="inline"
            >
              {" "}
              AdviceU.
            </Box>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant={useIsDesktop() ? "h3" : "h5"}
              sx={{
                fontWeight: "500",
                fontFamily: "productRegular",
              }}
            >
              Your Dream School Awaits
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              href="/pricing"
              sx={{
                marginTop: "20px",
                backgroundColor: PrimaryColor,
                width: useIsDesktop() ? "19vw" : "50vw",
                height: "50px",
                borderRadius: 15,
                fontFamily: "productBold",
              }}
            >
              DISCOVER MORE
              <ArrowForward />
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          xs={useIsDesktop() ? 6 : 12}
          sx={{
            textAlign: useIsDesktop() ? "right" : "center",
            marginTop: useIsDesktop() ? "0px" : "40px",
          }}
        >
          <img
            src={ApplicationHelp}
            style={{ width: useIsDesktop() ? "550px" : "100%" }}
            alt={""}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const containerStyle = {
  padding: { xs: "0px 20px 10px", md: "20px 166px 30px" },
  direction: "column",
  bgcolor: BackgroundColor,
  justifyContent: "center",
};

const gridStyle = {
  marginTop: "20px",
  maxWidth: "1500px",
  alignContent: "space-between",
  paddingBottom: "20px",
  marginLeft: { md: "-150px", lg: "0px" },
};
