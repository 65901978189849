import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  BackgroundColor,
  BackgroundColorLight,
  PrimaryColor,
} from "../AppThemeProvider";
import React, { useState } from "react";

export default function SubmitQuestionCard() {
  const [question, setQuestion] = useState("");
  const [email, setEmail] = useState("");

  const handleQuestionChange = (event: any) => {
    setQuestion(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // add handleSubmit logic
    setQuestion("");
    setEmail("");
  };

  return (
    <Box sx={boxStyle}>
      <Typography variant="h6" sx={{ fontFamily: "productBold" }}>
        Couldn't find an answer?
      </Typography>
      <Grid container spacing={1} sx={{ mt: "5px" }}>
        <Grid item>
          <Typography
            variant="body1"
            sx={{ fontStyle: "italic", fontFamily: "productRegular" }}
          >
            Leave your question and we will publish an answer.
          </Typography>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "10px" }}>
          <Grid item xs={12}>
            <TextField
              label="Your question"
              variant="outlined"
              fullWidth
              value={question}
              onChange={handleQuestionChange}
              sx={{
                background: "#FFFFFF",
                borderRadius: "5px",
                fontFamily: "productRegular",
                "& .MuiOutlinedInput-root": {
                  paddingTop: "25px",
                  paddingBottom: "25px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Your email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              sx={{
                background: "#FFFFFF",
                borderRadius: "5px",
                fontFamily: "productRegular",
                "& .MuiOutlinedInput-root": {
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "10px" }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={submitButtonStyle}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const boxStyle = {
  bgcolor: BackgroundColorLight,
  borderRadius: "20px",
  padding: "25px",
};

const submitButtonStyle = {
  borderRadius: "20px",
  color: BackgroundColor,
  bgcolor: PrimaryColor,
  textAlign: "center",
  width: "100%",
  fontFamily: "productBold",
};
